import React, {Component} from 'react';
import '../../assets/css/contact.css';
import '../../assets/css/register.css';
import {service} from "../../api/service";
import Swal from "sweetalert2";
import logo from '../../assets/img/logo.png'

const contactFields = {
    fullName: '',
    emailAddress: '',
    subject: '',
    comment: '',
}

class AboutBor extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            isEmpty: [],
            longitude: null,
            latitude: null,
            deviceInfo: null,
            ...contactFields
        }
        this.handleChange = this.handleChange.bind(this);
    }
    handleChange = (event) => {
        this.setState({
            [event.target.id]: event.target.value,
        })
        this.isError([event.target.id], '1px solid #ccc');
    }
    isError = (id,val) => {
        return document.getElementById(id).style.border = val;
    }
    booleanInput = (id) => {
        return !!document.getElementById(id).value;
    }
    valueInput = (id) => {
        return document.getElementById(id).value;
    }
    showEmpty = (id, val) => {
        return document.getElementById(id).style.display = val;
    }

    submitForm = async () => {
        await this.setState({isEmpty: []});
        const {fullName, emailAddress, subject, comment,longitude,latitude,deviceInfo, isEmpty} = this.state;
        ['fullName', 'emailAddress', 'comment'].map((item) => {
            if (!this.booleanInput(item)) {
                this.isError(item, '1px solid red');
                isEmpty.push(item);
                this.setState({isEmpty: isEmpty})
            }
        })
        if (isEmpty.length) {
            return;
        }
        let data = JSON.stringify({fullName, emailAddress, subject, comment,longitude,latitude,deviceInfo});
        this.setState({isLoading: true});
        await service.post_contact_us(data).then(async (ct) => {
            this.setState({isLoading: false});
            if (ct.flag) {
                await new Swal({
                    icon: ct.flag ? 'success' : 'error',
                    title: ct.flag ? 'Sent Successfully!' : 'Error Encountered!',
                    html: ct.message,
                    timer: 2000
                });
                await this.setState({...contactFields});
            }
        })
    }
    componentDidMount() {
        navigator.geolocation.getCurrentPosition(function(position) {
            console.log(position);
            this.setState({deviceInfo: position})
        });
        if (navigator.geolocation) {
            navigator.geolocation.watchPosition(function(position) {
                this.setState({latitude: position.coords.latitude, longitude: position.coords.longitude})
            });
        }
    }

    render() {
        const {fullName,emailAddress,subject,comment} = this.state;
        return (
            <div>
                <div className="pageTitle">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 col-sm-6">
                                <h1 className="page-heading">Contact</h1>
                            </div>
                            <div className="col-md-6 col-sm-6">
                                <div className="breadCrumb"><a href="#">Home</a> / Contact</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="listpgWraper">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 ">
                                <div className="container">

                                    <div>
                                        <div className="{/*cac-header*/}">
                                            <div className="cacinfo">
                                                <div className={'row'}>
                                                    <div className={'col-md-12'}>
                                                        {/*<h1 className={'mobile-hide'} style={{color: 'darkcyan'}}>CONTACT US</h1>*/}
                                                        <h4 style={{color:'darkcyan'}}>Corporate Affairs Headquarters</h4><hr /></div>

                                                    <div className={'col-md-6 col-md-offset-1'}>
                                                        {/*<h6>Write To Us.</h6><br />*/}
                                                        <div className={'section sectionID'} style={{margin: 5}}>
                                                            <div className={'col-md-12 col-xs-12'}>
                                                                <h3>Write to us!</h3>
                                                                <hr />
                                                                <div className={'input-group'}>
                                                                    <input type={'text'} className={'regInput'} id={'fullName'} value={fullName} onChange={this.handleChange} required={true} />
                                                                    <label className={'placeholder'}>Full Name *</label>
                                                                </div>
                                                                <div className={'input-group'}>
                                                                    <input type={'text'} className={'regInput'} id={'emailAddress'} value={emailAddress} onChange={this.handleChange} required={true} />
                                                                    <label className={'placeholder'}>Email Address *</label>
                                                                </div>
                                                                <div className={'input-group'}>
                                                                    <input type={'text'} className={'regInput'} id={'subject'} value={subject} onChange={this.handleChange} required={true} />
                                                                    <label className={'placeholder'}>Subject</label>
                                                                </div>
                                                                <div className={'input-group'}>
                                                                    {/*<label>Write Message *</label>*/}
                                                                    <textarea className={'contact-textarea'} id={'comment'} value={comment} placeholder={'Write Message *'} onChange={this.handleChange} rows={10} required={true} />
                                                                </div>
                                                                <div style={{
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    justifyContent: 'center',
                                                                    marginTop: 20
                                                                }}>
                                                                    <button
                                                                        style={{color: '#fff',background: '#308107'}}
                                                                        type={'submit'}
                                                                        className={'btn regSubmit'}
                                                                        onClick={this.submitForm}
                                                                    >
                                                                        Send Message
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className={'col-md-3'}>
                                                        {/*<h6>Contact Details.</h6><br />*/}
                                                        <div style={{margin: 5}}>
                                                            <div className={'col-md-12 section'} style={{background: '#fff', padding: 20, borderRadius: 10, color: 'green', textAlign: 'center'}}>
                                                                <img src={logo} className={'header-logo'} alt="Logo"/>
                                                                <hr />
                                                                <b><i className={'glyphicon glyphicon-home'}>&nbsp;</i>ADDRESS</b><br /><br />
                                                                <p>Plot 420, Tigris Crescent, off Aguyi Ironsi Street, Maitama Abuja. Nigeria</p><br />
                                                                <small>(Office 145)</small>
                                                                <br />
                                                                <br />
                                                                <hr />
                                                                <br />
                                                                <b><i className={'glyphicon glyphicon-envelope'}>&nbsp;</i>EMAIL</b><br /><br />
                                                                <p>cservice@cac.gov.ng</p><br />
                                                                {/*<p>protocol@cac.gov.ng</p><br />*/}
                                                                <hr />

                                                                <b><i className={'glyphicon glyphicon-phone-alt'}>&nbsp;</i>PHONE</b><br /><br />
                                                                <p>+234 708 062 9000</p>{/*, &nbsp; <em>+234-818-229-9016</em><br /><br />
                                                            <em>+234-908-740-1600</em>, &nbsp; <em>+234-908-740-1599</em><br /><br />
                                                            <em>+234-908-740-1598</em>, &nbsp; <em>+234-913-105-5341</em><br /><br />
                                                            <em>+234-913-105-5342</em>, &nbsp; <em>+234-913-105-5343</em>*/}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br />

            </div>
        );
    }
}

export default AboutBor;
