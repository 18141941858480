// eslint-disable-next-line no-unused-vars
import React from 'react';

export function EmailValidator(email) {
    /**
     * Author
     * @Olive Okechukwu
     **/
    let reg = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w\w+)+$/;
    if (email === null || email === '') {
        return 'undefined';
    } else if (reg.test(email)) {
        return email.toLowerCase();
    } else {
        return 'invalid';
    }
}
