// let ip = 'https://boradminapp.cac.gov.ng/borapp/api/';
// let ip = 'http://41.207.248.189:3003/api/';
let ip = 'https://borapp.cac.gov.ng/borapp/api/';

export const urls = {
    // ip: ip,
    // port: port,
    // endpoint: 'http://' + ip + ':' + port + '/api/',
    endpoint: ip,
    // login: 'login',
    login: 'auth/login',
    logout: 'logout',
    // searchDetails: 'get_psc',
    searchDetails: 'bor-search/get_psc',
    // PSCDetails: 'get_psc_details', //bor-search/get_psc_details
    PSCDetails: 'bor-search/get_psc_details',
    // dataDownload: 'dataDownload',
    dataDownload: 'bor-psc/date-range',
    // search_feedback: 'search_feedback',
    search_feedback: 'bor-search/post-feedback',
    recover_password: 'recover_password',
    userTypes: 'userTypes',
    // signup: 'signup',
    signup: 'auth/create-bor-user',
    getPortalUser: 'getPortalUser',
    update_portal_user: 'update_portal_user',
    profile_user_data: 'auth/access-token',
    contact_us: 'contact_us',
}
