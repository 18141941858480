// const url = 'https://jsonplaceholder.typicode.com/posts'; // useful

import React, {useEffect, useState} from "react";
import '../../assets/css/paginateStyle.css';
import {service} from "../../api/service";
import Swal from "sweetalert2";
import {dateConv} from "../../api/dateConv";

function CustPagination({ data, RenderComponent, title, dataLimit }) {

  let pageLimit = Math.ceil(data.length / dataLimit);
  const [pages] = useState(pageLimit);
  const [currentPage, setCurrentPage] = useState(1);
  const [verified,setVerified] = useState(false);
  const [isLoading,setIsLoading] = useState(false);

  const ver = async () => {
    let token = sessionStorage.getItem('token');
    return await service.getProfileData(token);
  }

  useEffect(() => {
    setCurrentPage(1);
    ver().then(r => {
      if (r.flag) {
        setVerified(r.flag)
      }
    });
  },[pageLimit])

  function goToNextPage() {
    setCurrentPage((page) => page + 1);
  }

  function goToPreviousPage() {
    setCurrentPage((page) => page - 1);
  }

  function changePage(event) {
    const pageNumber = Number(event.target.textContent);
    setCurrentPage(pageNumber);
  }

  const getPaginatedData = () => {
    const startIndex = currentPage * dataLimit - dataLimit;
    const endIndex = startIndex + dataLimit;
    return data.slice(startIndex, endIndex);
  };

  const getPaginationGroup = () => {
    let start = Math.floor((currentPage - 1) / pageLimit) * pageLimit;
    return new Array(pageLimit).fill().map((_, idx) => start + idx + 1);
  };

  const getSearchedData = async () => {
    const inputOptions = new Promise((resolve) => {
      setTimeout(() => {
        resolve({
          'json': 'JSON',
          'csv': 'CSV',
          'pdf': 'PDF'
        })
      }, 1000)
    })
    const {value: format} = await new Swal({
      title: 'CHOOSE FILE FORMAT',
      input: 'radio',
      inputOptions: inputOptions,
      inputValidator: (value) => {
        if (!value) {
          return 'You need to choose download format!'
        }
      }
    })
    if (format) {
      setIsLoading(true);
      await service.TriggerDownload(data, format);
      setIsLoading(false);
      new Swal({
        icon: data.length ? 'success' : 'warning',
        title: data.length ? '(' + data.length + ') JSON record(s) found.' : 'No data found',
        html: data.length ? 'Data downloaded successfully!' : 'Operation completed successfully!'
      });
    }
  }

  return (
    <div>
      {/*<h3 style={{color: 'green',fontFamily: 'courier'}}>{title}</h3>*/}

      {/* show the posts, 10 posts at a time */}
      <div className="row">
        <div style={{display: 'inherit'}}>
          {
            getPaginatedData().map((d, idx) => (
              <RenderComponent key={idx} data={d} />
            ))}
        </div>

      </div>
      <hr style={{borderWidth: 1,borderColor: '#ddd',marginTop: 100}} />

      <div className="row sticky" style={{marginTop: 50}}>

        <div>
          <div className="paginationClass">
            {/*previous button*/}
            <button
              disabled={currentPage === 1}
              onClick={goToPreviousPage}
              // className={'prev'}
              className={'prev ' + [currentPage === 1 && 'disabled']}
            >
              Prev
            </button>

            {/* show page numbers */}
            <div id={'keep-scrolling'}
                 className={'mobile-hide'}
                 style={{
                   display: 'flex',
                   overflowX: 'scroll',
                   width: 200,
                   alignItems: 'center',
                   justifyContent: 'start',
                 }}>
              {
                getPaginationGroup().map((item, index) => (
                  <button
                    key={index}
                    onClick={changePage}
                    className={'paginationItem ' + [currentPage === item ? 'active' : null]}
                  >
                    <b style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      padding: 20,
                    }}>{item}</b>
                  </button>
                ))
              }
            </div>

            <div className={'prevNext'}>
              <em>Current Page</em>  {currentPage} of {pageLimit}
            </div>

            {/* next button */}
            <button
              disabled={currentPage === pageLimit}
              onClick={goToNextPage}
              // className={'next'}
              className={'next ml-20 ' + [currentPage === pageLimit && 'disabled']}
            >
              Next
            </button>

            <select className={'mobile-hide'} style={{outline: 'none',fontSize: 18,marginLeft: 10,border: 'none'}}>
              <option selected={true}>Select Range</option>
              <option>1 - 99</option>
              <option>100 - 199</option>
              <option>200 - 299</option>
              <option>300 - 399</option>
              <option>400 - 499</option>
            </select>

            {
              verified && (
                <button className={'sds ml-20'} onClick={getSearchedData}>
                  Download Search
                </button>
              )
            }


          </div>
        </div>
      </div>
    </div>
  );
}

export default CustPagination;
