import React from 'react'
import { CCard, CCardBody, CCardHeader, CCol, CRow } from '@coreui/react'
import { CChartBar } from '@coreui/react-chartjs'
import '../../assets/css/dashboard.css'
import {Link} from "react-router-dom";
import TopBar from "./TopBar";
import {isLoggedIn} from "../../config/isLoggedIn";
import Home from "../body/Home";
// import CacBG from '../../assets/img/BG1.jpg'

const Dashboard = () => {
    // const [db, setDb] = useState([])
    /*  useEffect(() => {
      try {
        server.getUrl(`get_dashboard`).then(async (dbc) => {
          console.log(dbc)
          await setDb(dbc.data)
        })
      } catch (e) {
        console.log(e)
      }
    }, [])*/
  if (!isLoggedIn()) {
    return <Home />
  }
    return (
        <>
            <div className="pageTitle">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 col-sm-6">
                            <h1 className="page-heading">Dashboard</h1>
                        </div>
                        <div className="col-md-6 col-sm-6">
                            <div className="breadCrumb"><Link to={'/Download'}>Home</Link> / Dashboard</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={'dashBG'}>
                <CCol className={'shadow'} style={{marginBottom: 50}}>
                    <TopBar />
                </CCol>
                <div className={'container'} style={{marginBottom: 50}}>

                    <CRow className={'app-center'}>
                        <CCol className={'chart-border'}>
                            <CCard className="mb-4">
                                <CCardHeader>
                                    <b>Activity Bar Chart Annually</b>
                                    <br />
                                    <small>Bar chart represents number of visits for each month</small>
                                </CCardHeader>
                                <hr />
                                <CCardBody>
                                    <CChartBar
                                        data={{
                                            labels: [
                                                'January',
                                                'February',
                                                'March',
                                                'April',
                                                'May',
                                                'June',
                                                'July',
                                                'August',
                                                'September',
                                                'October',
                                                'November',
                                                'December',
                                            ],
                                            datasets: [
                                                {
                                                    label: 'Number of visits',
                                                    backgroundColor: '#218a7b',
                                                    data: [40, 20, 12, 39, 10, 40, 39, 70, 20, 12, 39, 10, 40, 39, 100],
                                                },
                                            ],
                                        }}
                                        labels="months"
                                    />
                                </CCardBody>
                            </CCard>
                        </CCol>
                        <CCol className={'chart-border'}>
                            <CCard className="mb-4" style={{ backgroundColor: 'transparent' }}>
                                <CCardHeader>
                                    <b>Activity Bar Chart Annually</b>
                                    <br />
                                    <small>Bar chart represents number of downloads for each month</small>
                                </CCardHeader>
                                <hr />
                                <CCardBody>
                                    <CChartBar
                                        data={{
                                            labels: [
                                                'January',
                                                'February',
                                                'March',
                                                'April',
                                                'May',
                                                'June',
                                                'July',
                                                'August',
                                                'September',
                                                'October',
                                                'November',
                                                'December',
                                            ],
                                            datasets: [
                                                {
                                                    label: 'Number of downloads',
                                                    backgroundColor: '#f15050',
                                                    data: [10, 20, 52, 39, 48, 40, 19, 100],
                                                },
                                            ],
                                        }}
                                        labels="months"
                                    />
                                </CCardBody>
                            </CCard>
                        </CCol>
                    </CRow>
                    {/*<CRow className={'app-center'}>
                    <CCol className={'chart-border'}>
                        <CCard className="mb-4" style={{ backgroundColor: 'transparent' }}>
                            <CCardHeader>
                                <small>Bar chart represents total logins for each month</small>
                            </CCardHeader>
                            <hr />
                            <CCardBody>
                                <CChartBar
                                    data={{
                                        labels: [
                                            'January',
                                            'February',
                                            'March',
                                            'April',
                                            'May',
                                            'June',
                                            'July',
                                            'August',
                                            'September',
                                            'October',
                                            'November',
                                            'December',
                                        ],
                                        datasets: [
                                            {
                                                label: 'Number of visits',
                                                backgroundColor: '#218a7b',
                                                data: [10, 20, 52, 39, 48, 40, 19],
                                            },
                                        ],
                                    }}
                                    labels="months"
                                />
                            </CCardBody>
                        </CCard>
                    </CCol>
                    <CCol className={'chart-border'}>
                        <CCard className="mb-4" style={{ backgroundColor: 'transparent' }}>
                            <CCardHeader>
                                <small>Bar chart represents total registration for each month</small>
                            </CCardHeader>
                            <hr />
                            <CCardBody>
                                <CChartBar
                                    data={{
                                        labels: [
                                            'January',
                                            'February',
                                            'March',
                                            'April',
                                            'May',
                                            'June',
                                            'July',
                                            'August',
                                            'September',
                                            'October',
                                            'November',
                                            'December',
                                        ],
                                        datasets: [
                                            {
                                                label: 'Number of visits',
                                                backgroundColor: '#218a7b',
                                                data: [10, 20, 52, 39, 48, 40, 19, 10, 20, 52, 39, 48, 40, 19],
                                            },
                                        ],
                                    }}
                                    labels="months"
                                />
                            </CCardBody>
                        </CCard>
                    </CCol>
                </CRow>*/}
                </div>
            </div>

        </>
    )
}

export default Dashboard
