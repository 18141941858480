import React, {Component} from 'react';

class Error404 extends Component {
    render() {
        return (
            <div>
                <div className="pageTitle">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 col-sm-6">
                                <h1 className="page-heading">404 Error Page</h1>
                            </div>
                            <div className="col-md-6 col-sm-6">
                                <div className="breadCrumb"><a href="#.">Home</a> / 404 Error</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="error-page-wrap nopadding">
                    <div className="container">
                        <div className="errormain">
                            <h2>404</h2>
                            <h3>Page was not Found</h3>
                            <div className="error-msg">
                                <p>The page you are looking is not available or has been removed. Try going to Home Page
                                    by using the button below.</p>
                                <a href="#/Search" className="btn">Go Home</a></div>
                        </div>
                    </div>
                </div>
                <br />
            </div>
        );
    }
}

export default Error404;
