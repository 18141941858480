import React, {Component} from 'react';
import {Link} from "react-router-dom";
import "../../assets/css/report.css";
import {titleCase} from "../../component/atoms/titleCase";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Swal from "sweetalert2";
import OverlayLoader from "overlay-loading-react";
import moment from "moment/moment";
import PhoneInput from 'react-phone-number-input'
import "react-phone-number-input/style.css";
import {EmailValidator} from "../../component/molecules/EmailValidator";
import {urls} from "../../api/urls";
// import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import {arrValidateID} from "../../component/atoms/spfile_1";
import {radioCSS} from "../../component/atoms/xtraCSS";
import Modal from "react-modal";
import {Toaster} from "../../component/molecules/Toaster";
import {toast} from "react-toastify";

const formFields = {
    orgIndividualName: '',
    orgIndividualEmail: '',
    orgIndividualPhone: '',
    companyName: '',
    companyRegNumber: '',
    companyEmail: '',
    companyPhone: '',
    natOfInc: '',
    nameOfPSC: '',
    firstNamePSC: '',
    otherNamePSC: '',
    nationality: '',
    dateOfBirth: '',
    idNumberOfPSC: '',
    idType: '',
    reportComment: '',
    specify: '',
};

const update9 = {
    1: `Incomplete Information`,
    2: `Unavailable Information`,
    3: `Discrepancy of Information`,
    4: `Other ( Please specify )`
}

const update16 = {
    1: `National Identification Number (NIN)`,
    2: `International Passport`,
    3: `Driver's Licence`,
    4: `Voter's Card`
}

class Report extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            isOpen: false,
            nullSelect: false,
            nullSelectMsg: 'Please enter a valid information!',
            longitude: null,
            latitude: null,
            device_info: null,
            ...formFields
        }
        this.handleChange = this.handleChange.bind(this);
    };
    cssBorder = (id,val) => {
        return document.getElementById(id).style.border = val;
    }
    booleanElement = (id) => {
        return !!document.getElementById(id).value;
    }
    valueElement = (id) => {
        return document.getElementById(id).value;
    }
    showHide = (id, val) => {
        return document.getElementById(id).style.display = val;
    }
    openModal = () => {
        this.setState({isOpen: true});
    }
    afterOpen = () => {
        // this.state.subtitle.style.color = '#f00';
    }
    closeModal = () => {
        this.setState({isOpen: false});
    }
    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value,
        });
        this.cssBorder(e.target.id,'1px solid #ccc');
        if (['orgIndividualEmail','companyEmail'].includes(e.target.id)) {
            this.showHide(e.target.id.substring(0,1) + 'Email', 'none');
        }
        if (['idNumberOfPSC'].includes(e.target.id)) {
            this.setState({nullSelect: false});
        }
    }
    handleSubmit = async () => {
        const { isLoading } = this.state;
        let countEmpty = [];
        arrValidateID.map((item) => {
            if (!this.booleanElement(item)) {
                this.cssBorder(item, '1px solid red');
                countEmpty.push(item);
            }
            if (item === 'orgIndividualEmail' || item === 'companyEmail') {
                if (EmailValidator(this.valueElement(item)) === 'invalid') {
                    this.showHide(item.substring(0,1) + 'Email' ,'block');
                    this.cssBorder(item, '1px solid red');
                    countEmpty.push(item);
                }
            }
        });
        if (!this.state.natOfInc) {
            this.cssBorder('natOfInc', '1px solid red');
            countEmpty.push('natOfInc');
        }
        if (this.state.natOfInc === 4 && !this.state.specify) {
            this.cssBorder('specify', '1px solid red');
            countEmpty.push('specify');
        }
        if (!this.state.idType) {
            this.cssBorder('idType', '1px solid red');
            countEmpty.push('idType');
        }
        if(this.state.idType && !this.state.idNumberOfPSC){
            this.cssBorder('idNumberOfPSCView', '1px solid red');
            countEmpty.push('idNumberOfPSCView');
        }
        if (countEmpty.length > 0) {
            toast('Fill out all required fields!')
            return;
        }
        this.setState({isLoading: true});
        await fetch(urls.endpoint + urls.search_feedback, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                orgIndName: this.state.orgIndividualName, //
                orgIndEmail: this.state.orgIndividualEmail, //
                orgIndPhone: this.state.orgIndividualPhone, //
                companyName: this.state.companyName, //
                companyRegNumber: this.state.companyRegNumber, //
                companyEmail: this.state.companyEmail, //
                companyPhoneNumber: this.state.companyPhone, //
                natureOfInconsistent: this.state.natOfInc === 4 ? this.state.specify : update9[this.state.natOfInc], //
                nameOfPSC: this.state.nameOfPSC, //
                firstNamePSC: this.state.firstNamePSC, //
                otherNamePSC: this.state.otherNamePSC, //
                nationality: this.state.nationality, //
                dateOfBirth: this.state.dateOfBirth, //
                idNumberOfPSC: this.state.idNumberOfPSC, //
                idType: update16[this.state.idType], //
                reportComment: this.state.reportComment,
                latitude: this.state.latitude,
                longitude: this.state.longitude,
                deviceInfo: this.state.device_info,
            }),
        }).then((res) => {
            res.json().then((jRes) => {
                this.setState({isLoading: false});
                new Swal({
                    title: jRes.code === '000' ? 'Sent Successfully!' : 'Error!',
                    html: jRes.message,
                    icon: jRes.code === '000' ? 'success' : 'error',
                });
                if (jRes.code === '000') {
                    setTimeout(() => {
                        this.setState({...formFields});
                    }, 2000);
                }
            });
        });
        setTimeout(() => {
            if (isLoading) {
                this.setState({isLoading: false});
                new Swal({
                    title: 'TIMEOUT!',
                    html: 'Server is taking a long time to respond.',
                    icon: 'error'
                });
            }
        }, 20000)
    }

    setIdType = (value) => {
        this.setState({idType: value,isOpen: true});
        this.cssBorder('idType', 'none');
    }
    setNatOfInc = (value) => {
        this.setState({natOfInc: value});
        this.cssBorder('natOfInc', 'none')
    }
    doneSelect = () => {
        if (this.state.idNumberOfPSC.length > 5) {
            this.setState({isOpen: false});
        } else {
            this.setState({nullSelect: true});
        }
    }
    componentDidMount() {
        navigator.geolocation.getCurrentPosition(function(position) {
            console.log(position);
            this.setState({device_info: position})
        });
        if (navigator.geolocation) {
            navigator.geolocation.watchPosition(function(position) {
                this.setState({latitude: position.coords.latitude, longitude: position.coords.longitude})
            });
        }
    }

    render() {
        const {
            orgIndividualName,
            orgIndividualEmail,
            orgIndividualPhone,
            companyName,
            companyRegNumber,
            companyEmail,
            companyPhone,
            natOfInc,
            nameOfPSC,
            firstNamePSC,
            otherNamePSC,
            nationality,
            dateOfBirth,
            idNumberOfPSC,
            idType,
            reportComment,
            isLoading,
            isOpen,
            nullSelect,
            nullSelectMsg,
            specify
        } = this.state;
        return (
            <div>
                <div className="pageTitle">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 col-sm-6">
                                <h1 className="page-heading">Report</h1>
                            </div>
                            <div className="col-md-6 col-sm-6">
                                <div className="breadCrumb">
                                    <Link to="/Home">Home</Link> / <a>Report</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={'shadow'}>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <h3 className={'text-bold center'}>
                            REPORTING INCONSISTENT INFORMATION <br />ON PERSONS WITH SIGNIFICANT CONTROL (PSC) REGISTER
                        </h3>
                    </div>
                </div>
                <div className={'container'}>
                    <div className={''}>
                        <br />
                        <div className={'formInfo alert-success'}>
                            This form may be use to report any inconsistent information about a PSC.<br />
                            This form may take approximately 3 minutes to complete.<hr />
                            All fields marked <rf>*</rf> are required!
                        </div>
                        <br />
                        <div className={'rptForm-section'}>
                            <div className={'rptForm-header'}>
                                <div className={'col-md-12 col-xs-12'}>
                                    <h5 className={'section-header'}>
                                        {titleCase('Details of reporting organization')}
                                    </h5>
                                    <hr />
                                </div>
                            </div>
                            <div className={'row rptForm-body'}>
                                <div className={'col-md-8 col-xs-12'}>
                                    <div className={'col-md-12 col-xs-12'}>
                                        <label className={'lbl'}>Reporting Organization / Individual <rf>*</rf></label>
                                        <input type={'text'} id={'orgIndividualName'} value={orgIndividualName} onChange={this.handleChange} />
                                        <i style={{color: 'green',margin: 5, fontSize: 12}}>
                                            Enter the name of the reporting organization / individual.
                                        </i>
                                    </div>
                                </div>
                                <div className={'col-md-4 col-xs-12'}>
                                    <label className={'lbl'}>Reporting Organization Email / Individual <rf>*</rf></label>
                                    <input type={'email'} id={'orgIndividualEmail'} value={orgIndividualEmail} onChange={this.handleChange} />
                                    <i style={{color: 'red', margin: 5, display: 'none'}} id={'oEmail'}>Invalid email format</i>
                                </div>
                            </div>
                            <div className={'row rptForm-body'}>
                                <div className={'col-md-12 col-xs-12'}>
                                    <div className={'col-md-5 col-xs-12'}>
                                        <label className={'lbl'}>Reporting Organization Phone # / Individual</label>
                                        <PhoneInput
                                            id={'orgIndividualPhone'}
                                            defaultCountry="NG"
                                            placeholder="Enter phone number"
                                            value={orgIndividualPhone}
                                            onChange={(no) => {
                                                this.setState({orgIndividualPhone: no})
                                                this.cssBorder('orgIndividualPhone','1px solid #ccc')
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <br />
                            <br />
                            <br />
                        </div>
                        <div className={'rptForm-section margin-top30'}>
                            <div className={'rptForm-header'}>
                                <div className={'col-md-12 col-xs-12'}>
                                    <h5 className={'section-header'}>
                                        {titleCase('Details of the company/LLP to be reported for inconsistent information')}
                                    </h5>
                                    <hr />
                                </div>
                            </div>
                            <div className={'row rptForm-body'}>
                                <div className={'col-md-7 col-xs-12'}>
                                    <div className={'col-md-12 col-xs-12'}>
                                        <label className={'lbl'}>Company/LLP </label><br />
                                        <input type={'text'} id={'companyName'} value={companyName} onChange={this.handleChange} />
                                    </div>

                                    <div className={'col-md-12 col-xs-12'}>
                                        <label className={'lbl'}>Company/LLP Registration Number </label><br />
                                        <input type={'text'} id={'companyRegNumber'} value={companyRegNumber} onChange={this.handleChange} />
                                    </div>

                                    <div className={'col-md-12 col-xs-12'}>
                                        <label className={'lbl'}>Company/LLP Email Address </label><br />
                                        <input type={'email'} id={'companyEmail'} value={companyEmail} onChange={this.handleChange} />
                                        <i style={{color: 'red', margin: 5, display: 'none'}} id={'cEmail'}>Invalid email format</i>
                                    </div>

                                    <div className={'col-md-8 col-xs-12'}>
                                        <label className={'lbl'}>Company/LLP Phone Number </label><br />
                                        <PhoneInput
                                            id={'companyPhone'}
                                            defaultCountry="NG"
                                            placeholder="Enter phone number"
                                            value={companyPhone}
                                            onChange={(no) => {
                                                this.setState({companyPhone: no})
                                                this.cssBorder('companyPhone','1px solid #ccc')
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className={'col-md-5 col-xs-12'}>
                                    <div className={'col-md-12 col-xs-12'}>
                                        <label className={'lbl'} id={'natOfInc'} style={{padding: 5,borderRadius: 5}}> Nature Of Inconsistent Information </label>
                                        <hr style={{margin: 3, width: '50%'}} />
                                        <input style={radioCSS} type="radio" checked={natOfInc === 1} name="natOfInc" onChange={()=>this.setNatOfInc(1)} />
                                        <label>{update9[1]}</label><br/>
                                        <input style={radioCSS} type="radio" checked={natOfInc === 2} name="natOfInc" onChange={()=>this.setNatOfInc(2)} />
                                        <label>{update9[2]}</label><br/>
                                        <input style={radioCSS} type="radio" checked={natOfInc === 3} name="natOfInc" onChange={()=>this.setNatOfInc(3)} />
                                        <label>{update9[3]}</label><br/>
                                        <input style={radioCSS} type="radio" checked={natOfInc === 4} name="natOfInc" onChange={()=>this.setNatOfInc(4)} />
                                        <label>{update9[4]}</label><br/>
                                    </div>
                                    {
                                        natOfInc === 4 && (
                                            <div className={'col-md-12 col-xs-12'}>
                                                <label className={'lbl'}>Specify Below </label><br />
                                                <input type={'text'} id={'specify'} value={specify} onChange={this.handleChange} />
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                        <div className={'rptForm-section margin-top30'}>
                            <div className={'rptForm-header'}>
                                <div className={'col-md-12 col-xs-12'}>
                                    <h5 className={'section-header'}>
                                        {titleCase('Details of inconsistent information')}
                                    </h5>
                                    <hr />
                                </div>
                            </div>
                            <div className={'row rptForm-body'}>
                                <div className={'col-md-6 col-xs-12'}>
                                    <div className={'col-md-12 col-xs-12'}>
                                        <label className={'lbl'}>Name Of The PSC </label><br />
                                        <input type={'text'} id={'nameOfPSC'} value={nameOfPSC} onChange={this.handleChange} />
                                    </div>
                                    <div className={'col-md-12 col-xs-12'}>
                                        <label className={'lbl'}>First name Of The PSC </label><br />
                                        <input type={'text'} id={'firstNamePSC'} value={firstNamePSC} onChange={this.handleChange} />
                                    </div>
                                    <div className={'col-md-12 col-xs-12'}>
                                        <label className={'lbl'}>Other name Of The PSC </label><br />
                                        <input type={'text'} id={'otherNamePSC'} value={otherNamePSC} onChange={this.handleChange} />
                                    </div>
                                    <div className={'col-md-12 col-xs-12'}>
                                        <label className={'lbl'}>Nationality </label><br />
                                        <input type={'text'} id={'nationality'} value={nationality} onChange={this.handleChange} />
                                    </div>
                                    <div className={'col-md-12 col-xs-12'}>
                                        <label className={'lbl'}>Date Of Birth </label>
                                        <DatePicker
                                            id={'dateOfBirth'}
                                            selected={dateOfBirth}
                                            onChange={(dt) => {
                                                this.setState({dateOfBirth: dt})
                                                this.cssBorder('dateOfBirth','1px solid #ccc')
                                            }}
                                            dateFormat={'dd/MM/yyyy'}
                                            showMonthDropdown
                                            showYearDropdown
                                            scrollableYearDropdown
                                            maxDate={moment().subtract(15, 'years')._d}
                                        />
                                        <i style={{color: 'green',margin: 5, fontSize: 12}}>
                                            Date Format - ( DD / MM / YYYY ).
                                        </i>
                                    </div>
                                </div>
                                <div className={'col-md-6 col-xs-12'}>
                                    <div className={'col-md-12 col-xs-12'}>
                                        <label className={'lbl'} id={'idType'} style={{padding: 5,borderRadius: 5}}>Identity Type </label>
                                        <hr style={{margin: 3, width: '50%'}} />
                                        <input style={radioCSS} type="radio" checked={idType === 1} name="idType" onChange={()=>this.setIdType(1)} />
                                        <label>{update16[1]}</label><br/>
                                        <input style={radioCSS} type="radio" checked={idType === 2} name="idType" onChange={()=>this.setIdType(2)} />
                                        <label>{update16[2]}</label><br/>
                                        <input style={radioCSS} type="radio" checked={idType === 3} name="idType" onChange={()=>this.setIdType(3)} />
                                        <label>{update16[3]}</label><br/>
                                        <input style={radioCSS} type="radio" checked={idType === 4} name="idType" onChange={()=>this.setIdType(4)} />
                                        <label>{update16[4]}</label><br/>
                                    </div>
                                    {
                                        idType && (
                                            <div className={'col-md-12 col-xs-12'}>
                                                <label className={'lbl'}>{update16[idType]} </label>(<small>Read only</small>)<br />
                                                <input className={'regInput'} id={'idNumberOfPSCView'} style={{fontWeight: 'bold'}} value={idNumberOfPSC} disabled={true}/>
                                                <i style={{color: 'red', margin: 5, display: 'none'}} id={'cEmail'}>Invalid email format</i>
                                            </div>
                                        )
                                    }
                                    <div className={'col-md-12 col-xs-12'}>
                                        <label className={'lbl'}>Any Comment?</label><br />
                                        <textarea
                                            className={'form-control'}
                                            rows={6}
                                            id={'reportComment'}
                                            style={{resize: 'none',background: 'ghostwhite'}}
                                            onChange={this.handleChange}
                                            value={reportComment}
                                        >
                                    </textarea>
                                    </div>
                                </div>
                            </div>
                            <br />
                            <br />
                            <br />
                        </div>
                        <div className={'row'}>
                            <div
                                className={'margin-top30'}
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}>
                                <button
                                    onClick={this.handleSubmit}
                                    className={'incSubmit'}
                                    type={'submit'}>
                                    Submit
                                </button>
                            </div>
                        </div>

                        <br />
                        <br />
                        <br />

                    </div>
                </div>

                <Modal
                    isOpen={isOpen}
                    onAfterOpen={this.afterOpen}
                    onRequestClose={this.closeModal}
                    shouldCloseOnOverlayClick={true}
                    style={{
                        overlay: {
                            position: 'fixed',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            backgroundColor: 'rgba(255,255,255,0.5)'
                        },
                    }}
                    className='modal-content'
                >
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        background: '#fff',
                        borderRadius: 10
                    }}>
                        <div className={'rptIdTypeModalSize'}>
                            <div>
                                <h6>Identification Type:</h6>
                                <h4 className={'text-success'}>{update16[idType]}</h4>
                                <hr />
                                <p style={{marginBottom: 20,fontSize: 13}}>Enter the ID number of ( {update16[idType]} ) that you selected.</p>
                                <div style={{marginBottom: 20}}>
                                    <div className={'input-group'}>
                                        <input type={'text'} id={'idNumberOfPSC'} value={idNumberOfPSC} className={'regInput'} onChange={this.handleChange} required={true} />
                                        <label className={'placeholder'}> Identification Number </label>
                                    </div>
                                    {nullSelect && (<i className={'text-danger'} style={{fontSize: 12}}>{nullSelectMsg}</i>)}
                                    <br /><br />
                                    <button
                                        className={'btn btn-success btn-round btn-lg btn-flat'}
                                        onClick={this.doneSelect}
                                    >
                                        Done
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
                <OverlayLoader
                    loadingText={'Sending Feedback - please wait..!'}
                    active={isLoading}
                    variant={'ripple'}
                />
            </div>
        );
    }
}

export default Report;
