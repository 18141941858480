import React, {Component} from 'react';
import 'react-date-range-dayjs/dist/styles.css'; // main style file
import 'react-date-range-dayjs/dist/theme/default.css'; // theme css file
import '../../assets/css/admin-main.css';
import { dateConv } from "../../api/dateConv";
import { service } from "../../api/service";
import Swal from "sweetalert2";
import OverlayLoader from "overlay-loading-react";
import { DateRangePicker } from 'react-date-range';
import { alertMSG } from "../../component/atoms/alertMSG";
import Home from "../body/Home";
import {isLoggedIn} from "../../config/isLoggedIn";
import {Link} from 'react-router-dom';
import TopBar from "./TopBar";
import {CCol} from "@coreui/react";
import CsvDownload from "react-json-to-csv";
import {isMobile} from "react-device-detect";


const DateValue = {
    startDate: new Date().setMonth(new Date().getMonth() - 2),
    endDate: new Date().setMonth(new Date().getMonth() - 2),
    key: 'selection'
}

class Download extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            value: DateValue,
            dataToDownload: [],
            calendar_count: 3
        }
    };

    Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 10000,
        timerProgressBar: false,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    });
    chooseFormat = async (startDate,endDate) => {
        const inputOptions = new Promise((resolve) => {
            setTimeout(() => {
                resolve({
                    'json': 'JSON',
                    'csv': 'CSV / EXCEL',
                    // 'pdf': 'PDF'
                })
            }, 1000)
        })

        const {value: format} = await new Swal({
            title: 'CHOOSE FILE FORMAT',
            input: 'radio',
            inputOptions: inputOptions,
            inputValidator: (value) => {
                if (!value) {
                    return 'You need to choose download format!'
                }
            }
        })
        if (format) {
            this.setState({isLoading: true});
            await service.DateRangeSearch(dateConv(startDate), dateConv(endDate)).then(async (ret) => {
                if (format === 'csv') {
                    await this.setState({dataToDownload: ret.data})
                    setTimeout(async () => {
                        await document.getElementById('triggerDownloadCSV').click()
                    },500)
                } else {
                    await service.TriggerDownload(ret.data, format);
                }
                await this.setState({isLoading: false, value: DateValue});
                let dataLength = ret.data.length;
                new Swal({
                    icon: dataLength ? 'success' : 'warning',
                    title: dataLength ? '(' + ret.data.length + ') Record(s) found.' : 'No data found',
                    html: dataLength ? 'Data downloaded successfully!' : 'Nothing to download!'
                });
            });
        }
    }
    SearchDownloadable = async () => {
        const {startDate, endDate} = this.state.value;

        if (!await service.Internet()) {
            await this.Toast.fire({
                icon: 'warning',
                title: alertMSG.offlineTitle,
                html: alertMSG.offlineHTML
            });
            return false;
        }
        new Swal({
            icon: 'question',
            title: 'From : ' + dateConv(startDate) + ' To ' + dateConv(endDate),
            html: 'Confirm between dates!',
            showCancelButton: true,
            focusConfirm: true,
            confirmButtonText: 'Proceed'
        }).then(async (result) => {
            if (result.isConfirmed) {
                await this.chooseFormat(startDate, endDate);
            }
        });
    }
    clearDates = () => {
        this.setState({value: DateValue});
    }
    componentDidMount() {
        if (isMobile) {
            this.setState({calendar_count: 1})
        } else {
            this.setState({calendar_count: 3})
        }
    }
    render() {
        if (!isLoggedIn()) {
            return <Home />
        }

        const {value,isLoading,dataToDownload,calendar_count} = this.state;

        return (
            <div>
                <CsvDownload style={{display: 'none'}} id={'triggerDownloadCSV'} data={dataToDownload} />
                <div className="pageTitle">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 col-sm-6">
                                <h1 className="page-heading">Search</h1>
                            </div>
                            <div className="col-md-6 col-sm-6">
                                <div className="breadCrumb"><Link to={'/Download'}>Home</Link> / Search</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={'dashBG'}>
                    <CCol className={'shadow'} style={{marginBottom: 10}}>
                        <TopBar />
                    </CCol>
                    <div className="container">
                        <div className="">
                            <label className={'container'} style={{textAlign: 'center',color: '#f8791f'}}>
                                To select a date range, click and hold on the date number which is the end-date, and drag it to start date.<br />
                                Date range starts from end-date to start date
                            </label>
                            <div style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}>
                                <div style={{marginBottom: 40,padding: 20}}>
                                    <div>
                                        <DateRangePicker
                                            onChange={item => {
                                                // console.log(item);
                                                this.setState({value: item.selection});
                                            }}
                                            showSelectionPreview={true}
                                            moveRangeOnFirstSelection={true}
                                            months={calendar_count}
                                            ranges={[value]}
                                            direction="horizontal"
                                            maxDate={new Date()}
                                            editableDateInputs={false}
                                            showPreview={true}
                                        />
                                    </div>
                                    <div style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        marginTop: 30,
                                        marginBottom: 50,
                                    }}>
                                        <button className="btn" id={'downloadButton'} onClick={this.SearchDownloadable}>
                                            <i className="fa fa-download">&nbsp;</i>
                                            &nbsp;Fetch Data
                                        </button>
                                        &nbsp;
                                        &nbsp;
                                        <button className="btn" id={'clearButton'} onClick={this.clearDates}>
                                            <i className="fa fa-close">&nbsp;</i>
                                            &nbsp;Clear
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <br />
                        </div>
                    </div>
                </div>
                <OverlayLoader
                    loadingText={'Fetching data - please wait..!'}
                    active={isLoading} variant={'ripple'}
                />
            </div>
        );
    }
}

export default Download;
