import React from 'react';
import {Link} from "react-router-dom";

const TopBar = () => {
  return (
    <div className={'container'}>
      <div className={'row'}>
        <div style={{display: 'flex',justifyContent: 'space-between'}}>
          <div style={{
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
          }}>
            <Link to={'/Dashboard'} className={'top_button'}>Dashboard</Link> &nbsp;  {/*dropbtn*/}
            <Link to={'/MyProfile'} className={'top_button'}>Profile</Link> &nbsp;
            <Link to={'/Download'} className={'top_button blink download_butt_nav'}>
              Click here to navigate to download page.
            </Link> &nbsp;
            <Link to={'/Download'} className={'top_button blink download_butt_nav_mob'} >
              Download
            </Link> &nbsp;
            {/*<a className={'dropdown'}>
                            <button className={'dropbtn top_button'}>Portal Users</button>
                            <div className={'dropdown-content'}>
                                <Link to="/ManageUsers">Manage Users</Link>
                                <Link to="/CreateUser">Create New Portal User</Link>
                                <Link to="/ApproveNewUsers">Approve New Users</Link>
                            </div>
                        </a>*/}
            {/*<button className={'top_button'}><i className={'fa fa-spin'}></i>Empty 1</button> &nbsp;
                        <button className={'top_button'}>Empty 2</button> &nbsp;
                        <button className={'top_button'}>Empty 3</button> &nbsp;*/}
          </div>
          <p className={'fa fa-clock lastLogon'}> Last logon: Invalid - 00:00:00 AM </p>
        </div>
      </div>
    </div>
  );
}

export default TopBar;
