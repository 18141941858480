import React, {Component} from 'react';
import 'react-date-range-dayjs/dist/styles.css'; // main style file
import 'react-date-range-dayjs/dist/theme/default.css'; // theme css file
import '../../assets/css/admin-main.css';
import Home from "../body/Home";
import {isLoggedIn} from "../../config/isLoggedIn";
import Modal from "react-modal";
import '../../App.css';
import company_logo from '../../assets/img/bor_company_logo.jpeg';
import {service} from "../../api/service";
import Swal from "sweetalert2";
import {Spinner, Sentry} from "react-activity"; //Dots,Levels,Sentry,Spinner,Squares,Digital,Bounce,Windmill
import "rc-tooltip/assets/bootstrap.css";
import Tooltip from "rc-tooltip";
import "react-activity/dist/library.css";
import {dateTimeConv} from "../../api/dateTimeConv";
import {radioCSS} from "../../component/atoms/xtraCSS";
import PhoneInput from "react-phone-number-input";
import {Link} from 'react-router-dom';
import {alertMSG} from "../../component/atoms/alertMSG";
import {toast} from "react-toastify";
import {env} from "../../component/atoms/env";
import TopBar from "./TopBar";
import {CCol} from "@coreui/react";

class MyProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isOpen: false,
      toEdit: '',
      toEditValue: '',
      toEditLabel: '',
      firstname: '',
      surname: '',
      other_name: '',
      last_logon: '',
      company_name: '',
      username: '',
      email: '',
      gender: '',
      occupation: '',
      state: '',
      city: '',
      lga: '',
      dob: '',
      phone: '',
      address: '',
      nin: '',
      // updateItem: '',
      updateValue: '',
      updatePassword: '',
    }
    this.handleChange = this.handleChange.bind(this);
  };

  handleChange = (e) => {
    this.setState({
      updateValue: e.target.value,
    });
  }
  pLine = {
    marginTop: 5,
    marginBottom: 5
  }
  openModal = () => {
    this.setState({isOpen: true});
  }

  afterOpen = () => {
    // this.state.subtitle.style.color = '#f00';
  }
  closeModal = () => {
    this.setState({isOpen: false});
  }
  checkState = async () => {
    setTimeout(() => {
      if (this.state.isLoading) {
        this.setState({isLoading: false})
      }
    }, 20000)
  }
  userDataUpdateFunction = async () => {
    const token = sessionStorage.getItem('token');
    try {
      this.setState({isLoading: true});
      await this.checkState();
      await service.getProfileData(token).then(async (pu) => {
        console.log(pu)
        await this.setState({
          firstname: pu.firstname,
          surname: pu.surname,
          other_name: pu.other_name,
          last_logon: pu.last_logon,
          company_name: pu.company_name,
          username: pu.username,
          email: pu.email,
          gender: pu.gender,
          occupation: pu.occupation,
          state: pu.state,
          city: pu.city,
          lga: pu.lga,
          dob: pu.dob,
          phone: pu.phone,
          address: pu.address,
          nin: pu.nin,
        });
        await this.setState({isLoading: false});
      });
    } catch (e) {
      console.log(e)
    }
  }
  async componentDidMount() {
    if (!await service.Internet()) {
      new Swal({
        icon: 'warning',
        title: '',
        html: env.message.no_internet
      })
    }
    await this.userDataUpdateFunction();
  }
  updateSet = async (item,value,label) => {
    this.setState({toEdit: item,toEditValue: value,toEditLabel: label,isOpen: true});
  }
  handleUpdate = async () => {
    const {toEdit,updateValue,updatePassword} = this.state;
    const type = sessionStorage.getItem('type');
    const token = sessionStorage.getItem('token');
    this.setState({isLoading: true});

    if (!toEdit || !updateValue || !updatePassword) {
      toast(env.message.required_fields)
      return;
    } else if (updatePassword.length < 5) {
      toast('Password should more than 4 characters!')
      return;
    }
    const updateData = JSON.stringify({toEdit,updateValue,updatePassword,type,token});
    await service.portal_user_update(updateData).then(async (res) => {
      if (res.flag) {
        this.setState({
          isOpen: false,
          toEdit: '',
          updateValue: '',
          updatePassword: '',
          isLoading: false
        });
        await this.userDataUpdateFunction();
      }
      new Swal({
        icon: res.flag ? 'success' : 'error',
        title: res.flag ? 'Updated!' : 'Error!',
        html: res.message,
        timer: res.flag ? 2000 : 5000,
      })
    })
  }

  render() {
    if (!isLoggedIn()) {
      return <Home />
    }
    const {isLoading,isOpen,toEdit,toEditValue,toEditLabel,firstname,
      surname,
      other_name,
      last_logon,
      company_name,
      username,
      email,
      gender,
      occupation,
      state,
      city,
      lga,
      dob,
      phone,
      address,
      nin,uPhone,updateValue} = this.state;

    return (
      <div>
        <div className="pageTitle">
          <div className="container">
            <div className="row">
              <div className="col-md-6 col-sm-6">
                <h1 className="page-heading">My Profile</h1>
              </div>
              <div className="col-md-6 col-sm-6">
                <div className="breadCrumb"><Link to={'/MyProfile'}>Home</Link> / My Profile</div>
              </div>
            </div>
          </div>
        </div>
        <div className={'dashBG'}>
          <CCol className={'shadow'} style={{marginBottom: 50}}>
            <TopBar />
          </CCol>
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div style={{display: 'flex',justifyContent: 'space-between'}}>
                  <h3 style={{color: 'green'}}>Hi, {isLoading ? <Sentry /> : firstname + ' ' + surname}</h3>
                  {/*<p className={'fa fa-clock lastLogon'}> LAST LOGON: {isLoading ? <Spinner /> : dateTimeConv(last_logon)}</p>*/}
                </div>
                <hr />
              </div>
              {/* <div className={'col-md-12'} style={{textAlign:'center',marginBottom: 20}}>
                                <label className={'alert-warning'}>
                                    To change your password. Please logout, and use the forgot password service.
                                </label>
                                <hr />
                            </div>*/}
              <div className={''}>
                <div className={'col-md-6'}>
                  <div className={'p-card'}>
                    <strong><small className={'text-success'}>COMPANY NAME: </small>&nbsp;<span>{isLoading ? <Sentry /> : company_name}</span></strong>
                    <hr style={this.pLine} />
                    <u className={'text-danger'}>Read only</u>
                  </div>
                  <div className={'p-card'}>
                    <strong><span className={'text-success'}>ADDRESS: </span>&nbsp;<span>{isLoading ? <Sentry /> : address}</span></strong>
                    <hr style={this.pLine} />
                    <button className={'resembleLink'} onClick={()=>this.updateSet('address',address,'Address')}>edit address</button>
                  </div>
                </div>
                <div className={'col-md-4'}>
                  <div className={'p-card'}>
                    <strong><span className={'text-success'}>USERNAME: </span>&nbsp;<span>{isLoading ? <Sentry /> : username}</span></strong>
                    <hr style={this.pLine} />
                    <u className={'text-danger'}>Read only</u>
                  </div>
                  <div className={'p-card'}>
                    <strong><span className={'text-success'}>DATE OF BIRTH: </span>&nbsp;<span>{isLoading ? <Sentry /> : dob}</span></strong>
                    <hr style={this.pLine} />
                    <u className={'text-danger'}>Read only</u>
                  </div>
                </div>
                <div className={'col-md-2'}>
                  <Tooltip
                    placement="top"
                    overlay={<span>Edit picture coming soon!</span>}
                  >
                    <div className={'p-card'}>
                      <img className={'pLogo'} src={company_logo} alt={'Logo'} style={{width: 140,boxShadow: '0 0 20px #aaa',cursor: 'pointer'}} />
                    </div>
                  </Tooltip>
                </div>
                <div className={'col-md-3'}>
                  <div className={'p-card'}>
                    <strong><span className={'text-success'}>FIRST NAME: </span>&nbsp;<span>{isLoading ? <Sentry /> : firstname}</span></strong>
                    <hr style={this.pLine} />
                    <button className={'resembleLink'} onClick={()=>this.updateSet('firstname',firstname,'First Name')}>edit first name</button>
                  </div>
                </div>
                <div className={'col-md-3'}>
                  <div className={'p-card'}>
                    <strong><span className={'text-success'}>SURNAME: </span>&nbsp;<span>{isLoading ? <Sentry /> : surname}</span></strong>
                    <hr style={this.pLine} />
                    <button className={'resembleLink'} onClick={()=>this.updateSet('surname',surname,'Surname')}>edit surname</button>
                  </div>
                </div>
                <div className={'col-md-3'}>
                  <div className={'p-card'}>
                    <strong><span className={'text-success'}>OTHER NAME: </span>&nbsp;<span>{isLoading ? <Sentry /> : other_name}</span></strong>
                    <hr style={this.pLine} />
                    <button className={'resembleLink'} onClick={()=>this.updateSet('other_name',other_name,'Other Name')}>edit other name</button>
                  </div>
                </div>
                <div className={'col-md-3'}>
                  <div className={'p-card'}>
                    <strong><span className={'text-success'}>LGA: </span>&nbsp;<span>{isLoading ? <Sentry /> : lga}</span></strong>
                    <hr style={this.pLine} />
                    <button className={'resembleLink'} onClick={()=>this.updateSet('lga',lga,'Local Government Area')}>edit lga</button>
                  </div>
                </div>
                <div className={'col-md-4'}>
                  <div className={'p-card'}>
                    <strong><span className={'text-success'}>EMAIL: </span>&nbsp;<span>{isLoading ? <Sentry /> : email}</span></strong>
                    <hr style={this.pLine} />
                    <u className={'text-danger'}>Read only</u>
                  </div>
                </div>
                <div className={'col-md-2'}>
                  <div className={'p-card'}>
                    <strong><span className={'text-success'}>TYPE: </span>&nbsp;<span>Portal User</span></strong>
                    <hr style={this.pLine} />
                    <u className={'text-danger'}>Read only</u>
                  </div>
                </div>
                <div className={'col-md-3'}>
                  <div className={'p-card'}>
                    <strong><span className={'text-success'}>PHONE #: </span>&nbsp;<span>{isLoading ? <Sentry /> : phone}</span></strong>
                    <hr style={this.pLine} />
                    <button className={'resembleLink'} onClick={()=>this.updateSet('phone_number',phone,'Phone Number#')}>edit phone #</button>
                  </div>
                </div>
                <div className={'col-md-3'}>
                  <div className={'p-card'}>
                    <strong><span className={'text-success'}>NIN: </span>&nbsp;<span>{isLoading ? <Sentry /> : nin}</span></strong>
                    <hr style={this.pLine} />
                    <u className={'text-danger'}>Read only</u>
                  </div>
                </div>
                <div className={'col-md-3'}>
                  <div className={'p-card'}>
                    <strong><span className={'text-success'}>STATE: </span>&nbsp;<span>{isLoading ? <Sentry /> : state}</span></strong>
                    <hr style={this.pLine} />
                    <button className={'resembleLink'} onClick={()=>this.updateSet('state',state,'State')}>edit state</button>
                  </div>
                </div>
                <div className={'col-md-3'}>
                  <div className={'p-card'}>
                    <strong><span className={'text-success'}>CITY: </span>&nbsp;<span>{isLoading ? <Sentry /> : city}</span></strong>
                    <hr style={this.pLine} />
                    <button className={'resembleLink'} onClick={()=>this.updateSet('city',city,'City')}>edit city</button>
                  </div>
                </div>
                <div className={'col-md-3'}>
                  <div className={'p-card'}>
                    <strong><span className={'text-success'}>GENDER: </span>&nbsp;<span>{isLoading ? <Sentry /> : gender}</span></strong>
                    <hr style={this.pLine} />
                    <button className={'resembleLink'} onClick={()=>this.updateSet('gender',gender,'Gender')}>edit gender</button>
                  </div>
                </div>
                <div className={'col-md-3'}>
                  <div className={'p-card'}>
                    <strong><span className={'text-success'}>OCCUPATION: </span>&nbsp;<span>{isLoading ? <Sentry /> : occupation}</span></strong>
                    <hr style={this.pLine} />
                    <button className={'resembleLink'} onClick={()=>this.updateSet('occupation',occupation,'Occupation')}>edit occupation</button>
                  </div>
                </div>

              </div>
            </div>
            <hr style={{border: '1px solid darkcyan',marginTop: 50,marginBottom: 50}} />
          </div>
        </div>
        <Modal
          isOpen={isOpen}
          onAfterOpen={this.afterOpen}
          onRequestClose={this.closeModal}
          shouldCloseOnOverlayClick={!isLoading}
          style={{
            overlay: {
              position: 'fixed',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: '#fff'
            },
          }}
          className='modal-content'
        >
          <div style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            background: '#fff'
          }}>
            <div style={{padding: 20,width: 400}}>

              <div className={''}>
                {
                  toEdit === 'gender' && (
                    <div style={{marginBottom: 10}}>
                      <h5 style={{marginBottom: 10}}>Please update "{toEditLabel}".</h5><br />
                      <div>
                        <input style={radioCSS} onClick={(gen)=>this.setState({updateValue: 'MALE'})} type="radio" name="gender" />
                        <label>Male</label> &nbsp;&nbsp;&nbsp;&nbsp;
                        <input style={radioCSS} onClick={(gen)=>this.setState({updateValue: 'FEMALE'})} type="radio" name="gender" />
                        <label>Female</label>
                      </div>
                    </div>
                  )
                }
                {
                  toEdit === 'phone_number' && (
                    <div style={{marginBottom: 10}}>
                      <h5 className={'text-success'} style={{marginBottom: 5}}>Edit "{toEditLabel}", <small>use the input below.</small></h5><br />
                      <p style={{fontWeight: 600}}>
                        {toEditLabel}: {toEditValue}
                      </p><br />
                      <PhoneInput
                        id={'phone'}
                        style={{height:50}}
                        value={uPhone}
                        defaultCountry="NG"
                        placeholder="Phone # *"
                        onChange={async (no) => this.setState({updateValue: no})}
                      />
                    </div>
                  )
                }
                {
                  ['address','firstname','surname','other_name','lga','state','city','occupation'].includes(toEdit) && (
                    <div style={{marginBottom: 10}}>
                      <h5 className={'text-success'} style={{marginBottom: 5}}>Edit "{toEditLabel}", <small>use the input below.</small></h5><br />
                      <p style={{fontWeight: 600}}>
                        {toEditLabel}: {toEditValue}
                      </p><br />

                      <Tooltip
                        placement="top"
                        overlay={<span>Required field</span>}
                      >
                        <input required={true} className={'form-control'} id={toEdit} value={updateValue} placeholder={toEditValue} style={{height:45}} onChange={this.handleChange} />
                      </Tooltip>
                    </div>
                  )
                }
                <div style={{marginBottom:50}}>

                  <div className={'inputBox'}>
                    <input type={'password'} required={true} onChange={(pwd)=>this.setState({updatePassword: pwd.target.value})} />
                    <span>Authentication Password</span>
                    <i></i>
                  </div>
                  <br /><br />
                  <button className={'btn btn-success btn-round btn-lg btn-flat'} onClick={this.handleUpdate}>Update My Profile</button>

                </div>

              </div>

            </div>

          </div>
        </Modal>
        {/*<OverlayLoader*/}
        {/*    loadingText={'Fetching data - please wait..!'}*/}
        {/*    active={isLoading} variant={'ripple'}*/}
        {/*/>*/}
      </div>
    );
  }
}

export default MyProfile;
