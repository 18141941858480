import React, {Component} from 'react';

class AboutBor extends Component {
    render() {
        return (
            <div>
                <div className="pageTitle">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 col-sm-6">
                                <h1 className="page-heading">About CAC BOR</h1>
                            </div>
                            <div className="col-md-6 col-sm-6">
                                <div className="breadCrumb"><a href="#">Home</a> / CAC BOR</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="listpgWraper">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 ">
                                <div className="container">

                                    <div style={{marginTop: 50}}>
                                        <div className="cac-header">
                                            <div className="cacinfo section">
                                                <div className={'col-md-12 text-success'}><h3>About CAC-BOR</h3><hr /><br /><br /></div>
                                                <p className="Standards" style={{fontSize: 17,padding: 20}}>The Corporate Affairs Commission has built a
                                                    register for Nigeria Beneficiary Ownership in accordance with
                                                    international standard of Beneficiary Ownership Data Standards
                                                    (BODS) under the guidance of World Bank. This portal
                                                    provides search function and information on Persons with Significant
                                                    Control (PSC). <br /><br />It also provides an Enterprise Service Bus (ESB)
                                                    gateway for data exchange in accordance with BODS in in JSON format.
                                                    <br /><br />This service
                                                    will provide the module for data exchange between CRP and external
                                                    applications/databases like Global Beneficiary Ownership Register
                                                    G-BOR. <br /><br />This document highlights the detailed information of persons
                                                    with significant
                                                    control currently collected by CAC-BOR applications and stored in
                                                    the register database. <br /><br />The data in the database forms the
                                                    basis for data exchange.<br />Any data exchange with external systems
                                                        like Open Ownership
                                                        will be communicated via a standard REST service, governed by
                                                        standard protocol of CAC Enterprise Service Bus.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br />

            </div>
        );
    }
}

export default AboutBor;
