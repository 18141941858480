import React from "react";
import {HashRouter, Routes, Route} from "react-router-dom";
// import 'bootstrap/dist/css/bootstrap.min.css';
import Home from './pages/body/Home'
import Standards from './pages/body/Standards'
import AboutBOR from './pages/body/AboutBOR'
import TopBar from './pages/online/TopBar'
import Faq from './pages/body/Faq'
import Contact from './pages/body/Contact'
import Error404 from './pages/body/Error404'
import Login from "./pages/body/Login";
import FooterBottom from "./pages/footer/FooterBottom";
import FooterTop from "./pages/footer/FooterTop";
import Header from "./pages/header/Header";
import Search from "./pages/body/Search";
import Download from "./pages/online/Download";
import MyProfile from "./pages/online/MyProfile";
import Report from "./pages/body/Report";
import Policy from "./pages/body/Policy";
import Recover from "./pages/body/Recover";
import Register from "./pages/body/Register";
import {isLoggedIn} from "./config/isLoggedIn";
import Dashboard from "./pages/online/Dashboard";
import 'react-toastify/dist/ReactToastify.css'
import {ToastContainer} from "react-toastify";

function App() {
    return (
        <HashRouter>
            <Header isLogin={isLoggedIn()} />
            <ToastContainer
                position={'top-center'}
                autoClose={5000}
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick={true}
                rtl={false}
                draggable={true}
                pauseOnHover={true}
                theme={'dark'}
            />
            <Routes>
                <Route>
                    <Route path={'/'} element={<Home />} />
                    <Route path={'/Home'} element={<Home />} />
                    <Route path="/Standards" element={<Standards />} />
                    <Route path="/AboutBOR" element={<AboutBOR />} />
                    <Route path="/TopBar" element={<TopBar />} />
                    <Route path="/Faq" element={<Faq />} />
                    <Route path="/Policy" element={<Policy />} />
                    <Route path="/Contact" element={<Contact />} />
                    <Route path="/Report" element={<Report />} />
                    <Route path="/Search" element={<Search />} />
                    <Route path="/*" element={<Error404 />} />
                    <Route path="/Register" element={<Register />} />
                    <Route path="Recover" element={<Recover />} />
                    <Route path="/Login" element={<Login />} />

                    <Route path="/Dashboard/*" element={<Dashboard />} />
                    <Route path="/MyProfile/*" element={<MyProfile />} />
                    <Route path="/Download/*" element={<Download />} />
                </Route>
            </Routes>
            <FooterTop />
            <FooterBottom />
        </HashRouter>
    );
}
export default App;
