export const arrValidateID = [
    'orgIndividualName',
    'orgIndividualEmail',
    // 'orgIndividualPhone',
    // 'companyName',
    // 'companyRegNumber',
    // 'companyEmail',
    // 'companyPhone',
    // 'natOfInc',
    // 'nameOfPSC',
    // 'firstNamePSC',
    // 'otherNamePSC',
    // 'nationality',
    // 'dateOfBirth',
    // 'idNumberOfPSC',
    // 'idType',
    'reportComment',
];

export const regArrValidateID = [
    'company_name','username', 'firstname', 'surname', 'email',
    'phone','nationality','nationality','address','city','state',
    'lga','occupation','password', 'passwordAgain'
];
