import React, {Component} from 'react';
import '../../assets/css/bootstrap.min.css';
import '../../assets/css/font-awesome.css';
import '../../assets/css/main.css';
import '../../assets/css/wizard.css';
import '../../assets/css/cssstyle.css';
import logo from '../../assets/img/logo.png'
import Header from "../header/Header";
// import FooterBottom from "../footer/FooterBottom";
import arms from "../../assets/img/footer-logo.png";
import FooterTop from "../footer/FooterTop";
import FooterBottom from "../footer/FooterBottom";
import Banner from "../../component/atoms/Banner";

class Login extends Component {





    render() {
        return (
            <div className="searchwrap">

                <div className="container">
                    <Banner />
                    <div className={'row'}>
                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            // height: '100vh',
                        }}>
                            <div className="box">
                                <div className={'form'}>
                                    <h2>Login</h2>

                                    <div className={'inputBox'}>
                                        <input type={'text'} required={true}/>
                                        <span>Username</span>
                                        <i></i>
                                    </div>
                                    <div className={'inputBox'}>
                                        <input type={'password'} required={true}/>
                                        <span>Password</span>
                                        <i></i>
                                    </div>
                                    <div className={'links'}>
                                        <a href="javascript:void(0)">Forgot Password</a>
                                        <a href="javascript:void(0)">Signup</a>
                                    </div>
                                    <input type={'submit'} value={'Login'} />
                                </div>
                            </div>
                        </div>
                    </div>

                </div>



            </div>
        );
    }
}

export default Login;
