import Swal from "sweetalert2";

export const Toaster = async (icon,title,html) => {

    let Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 10000,
        timerProgressBar: false,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    });

    return await Toast.fire({
        icon: icon,
        title: title,
        html: html
    });
}

