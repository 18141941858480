import React, {Component} from 'react';

class AboutBor extends Component {
    render() {
        return (
            <div>
                <div className="pageTitle">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 col-sm-6">
                                <h1 className="page-heading">Privacy Policy</h1>
                            </div>
                            <div className="col-md-6 col-sm-6">
                                <div className="breadCrumb"><a href="#">Home</a> / Privacy Policy</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="listpgWraper">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 ">
                                <div className="container">

                                    <div style={{marginTop: 50}}>
                                        <div className="cac-header">
                                            <div className="cacinfo section">
                                                <p className="Standards" style={{fontSize: 17,padding: 20}}>
                                                    <b>DISCLAIMER</b><br /><br />
                                                    The information available on this site is intended to be comprehensive but may not be exhaustive. Accordingly, it should not be regarded as a complete source of information. Readers are therefore advised to seek independent professional advise before acting on anything contained therein.
                                                    Corporate Affairs Commission (CAC) conducts basic checks on documents and information received to verify their accuracy. CAC takes no responsibility for the consequences and errors or omissions resulting from information submitted by entities.
                                                    CAC examines audited accounts submitted by entities to make sure they comply with basic requirements and are filed for the correct financial year. Where there is a complaint or observation based on non-compliance with the provisions of the Companies and Allied Matters Act (CAMA) 2020 or any other law, the entity that supplied same shall be sanctioned accordingly.
                                                    The CAC database is designed to meet the needs of a wide range of users wishing to interrogate information on-line. Due to limitations of equipment and bandwidth, we may sometimes not be in a position to support bulk downloads.<br /><br /><br /><br />
                                                    <b>DATA PROTECTION AND PRIVACY POLICY</b><br /><br />
                                                    Corporate Affairs Commission (CAC) collects personal data from customers for a variety of reasons including making it available to the public or other authorised user. This data also includes data of customers writing in with complaints and those signing up to use our digital services.
                                                    Details on how CAC handles these data is in line with the Companies and Allied Matters Act (CAMA) 2020, Companies Regulations (CR) 2021, Persons with Significant Control Regulations (PSCR) 2022, the Nigeria Data Protection Regulations (NDPR) 2019 and any other prevailing law in that regard.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br />

            </div>
        );
    }
}

export default AboutBor;
