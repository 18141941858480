import React, {Component} from 'react';

class Faq extends Component {
    render() {
        return (
            <div>
                <div className="pageTitle">
                    <div className="row">
                        <div className="container">

                            <div className="col-md-6 col-sm-6">
                                <h1 className="page-heading">FAQ</h1>
                            </div>
                            <div className="col-md-6 col-sm-6">
                                <div className="breadCrumb"><a href="#">Home</a> / <b>Frequently Ask Questions</b></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="listpgWraper">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 ">
                                <div className="container">

                                    <div style={{marginTop: 50}}>
                                        <div className="cac-header">
                                            <div className="cacinfo section">

                                                <p className="Standards" style={{fontSize: 17,padding: 20}}>

                                                    <h6>FREQUENTLY ASKED QUESTIONS ABOUT BENEFICIAL OWNERSHIP REGISTER </h6>
                                                    {/*<p>Disclaimer:</p><br /><br/>*/}
                                                    <br />
                                                    This series of frequently asked questions (FAQs) is intended to assist in the general understanding of the concept of Beneficial Ownership, otherwise referred to as Persons with Significant Control (PSC) in Nigeria legal framework.  It neither constitutes legal advice nor can it be construed to define, limit or otherwise restrict the Corporate Affairs Commission in the lawful fulfilment of its obligations.<br /><br />
                                                    <b>1).	What is Beneficial Ownership Register?</b><br />
                                                    Beneficial Ownership Register is the right to some share of a legal entity’s income or asset (ownership) of the right to direct or influence the entity’s activities (control).  The natural persons who are the beneficial owners of such entities are referred to as Persons with Significant Control (PSC) under the companies and Allied Matters Act (CAMA) 2020.
                                                    Beneficial Ownership Transparency (BOT) helps us understand how companies and other legal entities are owned and controlled.  Even when companies own companies, individuals almost always appear at the end of the ownership chain.  They ultimately benefit from companies’ financial success and often share their failures.<br /><br />
                                                    <b>2).	Who is a Beneficial Owner of an Entity?</b><br />
                                                    A beneficial owner (BO) otherwise referred to in CAMA 2020 as a person with significant control (PSC) is any natural person with a substantial and significant interest or control over a company.  This can be a direct interest through, for example, owning a substantial proportion of shares in the company or indirect such as influencing significant decisions related to the running of the business of a company.
                                                    The companies and Allied Matters Act, 2020 defines a person with significant control as any person holding directly or indirectly not less than 5% shares, interest, voting rights, and right to exercise significant influence or control over a company or Limited Liability Partnership.<br /><br />
                                                    <b>3).	Implications of Implementing Beneficial Ownership Register Transparency Principles in Nigeria</b><br />
                                                    While Owners benefit from the successes of the organizations they own, they often evade detection when those same organizations they own are investigated for corruption or illicit dealing. Implementing Beneficial Ownership Transparency gives a comprehensive overview of the companies operating in your economy and who owns them.  It is the key piece of information required in investigations for corrupt or criminal activities and illicit financial flows.<br /><br />
                                                    <b>4).	Does this requirement to register beneficial owners apply to all companies?</b><br />
                                                    Yes, it applies to all companies and limited liability partnerships registered in Nigeria and elsewhere.  it does not, however, apply to sole proprietors and Partnerships.
                                                    Disclosure is also required for foreign companies granted exemption to do business in Nigeria and also state owned entities.<br /><br />
                                                    <b>5.	What is the Reporting Threshold for Beneficial Owners?</b><br />
                                                    The threshold for reporting beneficial ownership information is 5% interest in shares, interest, voting rights, right to exercise significance influence or control over a company or limited liability partnership.<br /><br />
                                                    <b>6).	Beneficial Ownership Register (BOR) or Persons with Significant Control Register (PSCR).
                                                        What is it?</b><br />
                                                    A Beneficial Ownership Register is an official record of names or items held in a database.
                                                    What difference does/would it make?
                                                    The Nigerian Beneficial Ownership Register will give access to company ownership information to the government, companies and citizens empowering them with the knowledge they need to investigate malicious company behavior and hold them accountable.  It will constrict the space in which corruption and criminal acts can occur and end the use of anonymous shelf companies that facilitate these activities.<br /><br />
                                                    <b>7).	Why Beneficial Ownership Transparency in Nigeria?</b><br />
                                                    Implementing Beneficial Ownership Transparency Principles will help curb illicit financial flows and their negative impact while improving transparency in the award of government contracts, licenses’ and permits in Nigeria.
                                                </p>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br />

            </div>
        );
    }
}

export default Faq;
