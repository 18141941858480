import React, {Component} from 'react';
import '../../assets/css/bootstrap.min.css';
import '../../assets/css/font-awesome.css';
import '../../assets/css/main.css';
import '../../assets/css/wizard.css';
import '../../assets/css/cssstyle.css';
import '../../assets/css/homeStyle.css';
import Banner from "../../component/atoms/Banner";
import logo from '../../assets/img/Test.png';
import {Dropdown} from "../../component/atoms/Dropdown";
import OverlayLoader from "overlay-loading-react";
import Search from "./Search";
import {urls} from "../../api/urls";
import {toast} from "react-toastify";

class Home extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            searchItem: null,
            searchType: null,
            initialized: false
        }
        this.handleChange = this.handleChange.bind(this);
    }
    handleChange = (event) => {
        this.setState({
            [event.target.id]: event.target.value,
        })
    }
    cacSubmit = async () => {
        const {searchItem, searchType} = this.state;
        if (!searchItem || !searchType) {
            toast('Please fill up required fields!')
            return false;
        }
        if (searchType === 'ENTITY NAME' && searchItem.length < 4) {
            await toast('Your search item should have more than 3 letter words!');
            return false;
        }
        this.setState({initialized: true});
    }
    async componentDidMount() {
        await fetch(urls.endpoint + `bor-psc/total-visits`,
            {method: 'POST'})}

    render() {
        const { isLoading, initialized, searchItem, searchType } = this.state;
        if (initialized) {
            return <Search item={searchItem} type={searchType} />
        }
        return (
            <div>
                <div className="searchwrap">
                    <div className="container">
                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}>
                            <img src={logo} alt={'logo'} style={{width: 180}} />
                        </div>
                        <Banner />
                        <div className="main_title">
                            <h2 className={'mobile-fs1'}>
                                Discover the <strong>online</strong> search!
                            </h2>
                        </div>

                    </div>
                </div>
                <div className={'container'}>
                    <div id={'search-holder'} className={'home-search-push-up bg-light'}>
                        <input
                            id={'searchItem'}
                            placeholder={'Start typing..'}
                            onChange={this.handleChange}
                            style={{width: '45%'}}
                        />
                        <select
                            id={'searchType'}
                            style={{width: '22%'}}
                            onChange={this.handleChange}
                        >
                            {Dropdown()}
                        </select>
                        <button
                            id={'searchSubmit'} style={{width: '18%'}}
                            onClick={this.cacSubmit}>
                            Search
                        </button>
                    </div>
                    <div className={searchType === 'PSC FULLName' ? '' : 'hide'} style={{textAlign: 'center',marginTop: 10,fontWeight: 500,color: '#fa8d5e'}}>
                        Please enter full name in the format: (1. sur-name * / 2. first name * / 3. other name)
                    </div>
                    <div className="margin_120_95">
                        <div className="row">
                            <div className="col-md-4 col-lg-4">
                                <div className="box_feat" id="icon_1"><span></span><h3>Locate <br /> Company address</h3></div>
                            </div>
                            <div className="col-md-4 col-lg-4">
                                <div className="box_feat" id="icon_2"><span></span><h3>Follow <br /> company</h3></div>
                            </div>
                            <div className="col-md-4 col-lg-4">
                                <div className="box_feat" id="icon_3"><h3>View <br /> company data</h3></div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div style={{textAlign: 'center'}}>
                            <p style={{fontSize: 20, padding: 20, color: '#ccc'}}>
                                FIND PERSONS WITH SIGNIFICANT CONTROL IN CAC BENEFICIAL OWNERSHIP REGISTER
                            </p>
                        </div>
                    </div>
                </div>
                {<OverlayLoader loadingText={'Searching - please wait..!'} active={isLoading} variant={'ripple'} />}
            </div>
        );
    }
}

export default Home;
