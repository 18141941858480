import React from "react";

const Banner = () => {
    return (
        <>
            <p className={'mobile-fs1'} style={{color: '#4fdb65',marginTop: 10,lineHeight: 1.1}}>
                FEDERAL REPUBLIC OF NIGERIA <br className={'mobile-hide'} />
                PERSONS WITH SIGNIFICANT CONTROL (PSC)<br />
                REGISTER
            </p>
            <br />
        </>
    )
}
export default Banner;
