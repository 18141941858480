import React, {Component} from 'react';
import {Link} from "react-router-dom";
import "../../assets/css/register.css";
import {titleCase} from "../../component/atoms/titleCase";
import "react-datepicker/dist/react-datepicker.css";
import OverlayLoader from "overlay-loading-react";
import "react-phone-number-input/style.css";
import {service} from "../../api/service";
// import Swal from "sweetalert2";
import {Toaster} from "../../component/molecules/Toaster";
import PasswordChecklist from "react-password-checklist";

class Recover extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            isPasswordOk: false,
            isOTP: false,
            username: '',
            password: '',
            passwordAgain: '',
            code: ''
        }
        this.handleChange = this.handleChange.bind(this)
    };

    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value,
        });
    }

    countDownTimer = () => {
        let time_left = 30;
        let downloadTimer = setInterval(function(){
            if(time_left <= 0){
                clearInterval(downloadTimer);
                document.getElementById("countdown").style.pointerEvents = "auto";
                document.getElementById("countdown").innerHTML = "Didn't get code? Click here to resend!";
            } else {
                document.getElementById("countdown").style.pointerEvents = "none";
                document.getElementById("countdown").innerHTML = time_left + " Seconds remaining";
            }
            time_left -= 1;
        }, 1000);
    }

    resendOTP = async () => {
        await service.Recover_Password(this.state.username, null, null, false);
        await Toaster('','','Please check your email!');
        await this.countDownTimer();
    }

    handleRecovery = async () => {
        const {username,password,code,isOTP,isPasswordOk} = this.state;
        this.setState({isLoading: true});
        if (isOTP && !isPasswordOk) {
            await Toaster('warning', '', 'Invalid password!');
        }
        await service.Recover_Password(username,password,code,isOTP).then(async (res) => {
            console.log(res)
            if (res.flag && !isOTP) {
                await this.setState({isLoading: false,isOTP: true,username: res.data});
                await this.countDownTimer();
            } else if (res.flag && isOTP) {
                await this.setState({isLoading: false,isOTP: false,username: ''});
            }
            await this.setState({isLoading: false});
            await Toaster('','',res.message);
        });
    }

    viewPassword = (id) => {
        let x = document.getElementById(id);
        if (x.type === "password") {
            x.type = "text";
        } else {
            x.type = "password";
        }
    }

    render() {
        const {isLoading,username,isOTP,code,password,passwordAgain} = this.state;
        return (
            <div>
                <div className="pageTitle">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 col-sm-6">
                                <h1 className="page-heading">Password Recovery</h1>
                            </div>
                            <div className="col-md-6 col-sm-6">
                                <div className="breadCrumb">
                                    <Link to="/Home">Home</Link> / <a>Password Recovery</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={'shadow'}>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}>
                        <h3 className={'center'} style={{color: 'darkcyan'}}>
                            PORTAL USER PASSWORD RECOVERY
                        </h3>
                    </div>
                </div>
                <div className={'container'}>
                    <div className={''}>

                        <div className={'rptForm-section'} style={{marginTop: 20,marginBottom: 50}}>
                            <div className={'rptForm-header'}>
                                <div className={'col-md-12 col-xs-12'}>
                                    <h5 className={'section-header'}>
                                        {titleCase('Invalid username or password?')}
                                    </h5>
                                    <hr />
                                </div>
                            </div>
                            <div className={'row rptForm-body'}>
                                <div className={'col-md-12'}>
                                    <p className={'alert alert-warning'} style={{marginBottom: 20}}>
                                        This may occur when using the wrong credential to log in to your portal. To resolve this, use either your e-mail address, phone number or accreditation number submitted while creating the account as the username for login. <br /><br />If you have forgotten your password, click on the “<b>Password Recovery</b>” button and follow the link sent to your e-mail address to change your password.
                                    </p>
                                </div>
                                <div className={'col-md-6 col-md-offset-3 col-xs-12'}>
                                    {
                                        !isOTP ? (
                                            <div>
                                                <br />
                                                <div style={{borderWidth: 2, borderColor: '#000',marginBottom: 10}}>
                                                    <div id={'section'} className={'fiftyFifty'}>
                                                        <h3>Password Recovery</h3>
                                                        <hr />
                                                        <p style={{marginBottom: 5,color: 'darkcyan'}}>
                                                            Enter your registered <b>username</b> or <b>email</b><br />
                                                            Then follow the prompt to recover your password.
                                                        </p>
                                                        <br />
                                                        <br />
                                                        <div className={'input-group'}>
                                                            <input type={'text'} className={'regInput'} id={'username'} value={username} onChange={this.handleChange} required={true} />
                                                            <label className={'placeholder'}>Username / Email</label>
                                                        </div>
                                                        <button className={'btn btn-warning regSubmit'} onClick={this.handleRecovery}>
                                                            Recover
                                                        </button>

                                                        {/* <div style={{display: 'flex',flexDirection: 'row',justifyContent: 'space-between',paddingRight: 10,paddingLeft: 10}}>
                                                    <div><p><Link to={'/Login'}>Login</Link></p></div>
                                                    <div><p><Link to={'/Register'}>Registration</Link></p></div>
                                                </div>*/}
                                                    </div>
                                                    <br />

                                                </div>
                                            </div>
                                        ) : (
                                            <div>
                                                <br />
                                                <div style={{borderWidth: 2, borderColor: '#000',marginBottom: 10}}>
                                                    <div id={'section'} className={'fiftyFifty'}>
                                                        <h3>Create New Password</h3>
                                                        <hr />
                                                        <p className={'alert alert-warning'} style={{marginBottom: 5,color: 'darkcyan'}}>
                                                            Please don't close this window.<br />
                                                            A six alpha-numeric code has been sent to your email..
                                                        </p>
                                                        <br />
                                                        <br />
                                                        <div className={'input-group'}>
                                                            <input type={'text'} className={'regInput'} id={'code'} value={code} onChange={this.handleChange} required={true} />
                                                            <label className={'placeholder'}>Enter the code sent through your email</label>
                                                        </div>
                                                        <div className={'row'}>
                                                            <div className={'col-md-12 col-xs-12'}>
                                                                <div className={'col-md-6 col-xs-12 input-group'}>
                                                                    <input type={'password'} value={password} className={'regInput'} id={'password'} required={true} onChange={this.handleChange} />
                                                                    <label className={'placeholder'}>Create new password *</label>
                                                                    <i className={'fa fa-eye'} onClick={()=>this.viewPassword('password')}></i>
                                                                </div>
                                                                <div className={'col-md-6 col-xs-12 input-group'}>
                                                                    <input type={'password'} value={passwordAgain} className={'regInput'} id={'passwordAgain'} required={true} onChange={this.handleChange} />
                                                                    <label className={'placeholder'}>Re-type new password *</label>
                                                                    <i className={'fa fa-eye'} onClick={()=>this.viewPassword('passwordAgain')}></i>
                                                                </div>
                                                            </div>
                                                            <div className={'col-md-12 col-xs-12'}>
                                                                <PasswordChecklist
                                                                    // rules={["minLength","match","specialChar"]}
                                                                    rules={["minLength","specialChar","number","capital","match"]}
                                                                    minLength={5}
                                                                    value={password}
                                                                    valueAgain={passwordAgain}
                                                                    onChange={(isValid) => {this.setState({isPasswordOk: isValid})}}
                                                                />
                                                            </div>
                                                            <br />
                                                        </div>
                                                        <button className={'btn btn-primary regSubmit'} style={{marginTop: 50}} onClick={this.handleRecovery}>
                                                            Reset Password
                                                        </button>

                                                    </div>
                                                    <br />
                                                    <a href="javascript:void(0)"
                                                       onClick={this.resendOTP}
                                                       className={'col-md-12 col-xs-12'}
                                                       id={'countdown'}>
                                                    </a>
                                                </div>
                                            </div>
                                        )
                                    }
                                </div>
                            </div>

                            <br />
                            <br />
                            <br />
                        </div>


                        <br />
                        <br />
                        <br />

                    </div>
                </div>


                <OverlayLoader
                    loadingText={'Sending Feedback - please wait..!'}
                    active={isLoading}
                    variant={'ripple'}
                />

            </div>
        );
    }
}

export default Recover;
