import React from 'react';

function Standards() {
    return (
        <div>
            <div className="pageTitle">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 col-sm-6">
                            <h1 className="page-heading">Standards</h1>
                        </div>
                        <div className="col-md-6 col-sm-6">
                            <div className="breadCrumb"><a href="#">Home</a> / <b>Standards</b></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="listpgWraper">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 ">
                            <div className="container">

                                <div style={{marginTop: 50}}>
                                    <div className="cac-header">
                                        <div className="cacinfo section">
                                            <div className={'col-md-12 text-success'}><h3>Standards</h3><hr /><br /><br /></div>
                                            <p className="Standards" style={{fontSize: 17,padding: 20}}>

                                                <b>The Corporate Affairs Commission</b> as the Registry of Companies in Nigeria is hosting the Beneficial Ownership Register which in our legal framework is referred to as Register of <b>Persons with Significant Control (PSC)</b>.<br /><br />
                                                <b>The Companies and Allied Matters Act, (CAMA), 2020</b> has provided the legal framework for beneficial ownership information disclosure in Nigeria.<br /><br />
                                                <b>The implementation of the new legal regime</b> has warranted the design, development and deployment of an electronic filing system that will accommodate information on persons with significant control in registered entities.<br /><br />
                                                The threshold for disclosure is put at 5% minimum in terms of shareholding, interest, voting rights, right to appoint/remove Directors or any significant influence or control over a registered entity.  The quantum of powers to be held or exercised for this purpose can be directly or indirectly.<br /><br />
                                                The disclosure required under this legal framework is focused on the natural person who is the ultimate beneficial owner in an entity.<br /><br />
                                                This Register of persons with significant control (PSC) provides the interface for reliable, effective and productive disclosure framework for new registrations and post-registration activities in line with global best practices.<br /><br />

                                            </p>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <br />

        </div>
    );
}
export default Standards;
