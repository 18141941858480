import React, {Component} from 'react';

class FooterBottom extends Component {
    render() {
        return (
            <div className="copyright">
                <div className="container">
                    <div className="col-md-12 col-sm-12 col-xs-12 bttxt">
                        Copyright &copy; {new Date().getFullYear()} Corporate Affairs Commission. All Rights Reserved.
                        <span className="float-right pull-right">
                        P<i className="fa fa-power-off"></i>wered by:
                    <a href="javascript:void(0);" style={{color: 'rgb(98,236,21)'}}> Oasis Management Company</a>
                    </span>
                    </div>
                </div>
            </div>
        );
    }
}

export default FooterBottom;
