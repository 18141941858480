import React, {Component} from 'react';
import arms from '../../assets/img/footer-logo.png'

class FooterTop extends Component {
  render() {
    return (
      <div className="footerWrap">
        <div className="container">
          <div className="col-md-12 col-sm-12 col-xs-12">
            <div className="col-md-6 col-sm-12">
              <div>
                <img src={arms} alt="Your alt text here" />
              </div>
              <div className="social"><a href="javascript:void(0);"> <i className="fa fa-facebook-square" aria-hidden="true"></i></a>
                <a href="javascript:void(0);"><i className="fa fa-twitter-square" aria-hidden="true"></i></a>
                <a href="javascript:void(0);"><i className="fa fa-google-plus-square" aria-hidden="true"></i></a>
                <a href="javascript:void(0);"><i className="fa fa-linkedin-square" aria-hidden="true"></i></a>
                <a href="javascript:void(0);"><i className="fa fa-youtube-square" aria-hidden="true"></i></a>
              </div>
            </div>
            <div className="col-md-6 col-sm-6">
              <h5 style={{marginTop: 20}}>Quick Links</h5>
              <ul className="quicklinks">
                <li><a href="#/Search">Search for Business Entity</a></li>
                {/*<li><a href="javascript:void(0);">Buy Business Information</a></li>*/}
                <li><a href="#/AboutBOR">About CAC-BOR</a></li>
                <li><a href="#/Faq">F A Q</a></li>
                <li><a href="#/Policy">Privacy Policy</a></li>
                <li><a href="#/Contact">Contact Us</a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default FooterTop;
