import React, {Component} from 'react';
import {Link} from "react-router-dom";
import "../../assets/css/register.css";
import "react-datepicker/dist/react-datepicker.css";
import OverlayLoader from "overlay-loading-react";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import PasswordChecklist from "react-password-checklist"
import {service} from "../../api/service";
import {EmailValidator} from "../../component/molecules/EmailValidator";
import {Toaster} from "../../component/molecules/Toaster";
import Swal from "sweetalert2";
import {regArrValidateID} from "../../component/atoms/spfile_1";
import DatePicker from "react-datepicker";
import {radioCSS} from "../../component/atoms/xtraCSS";
import moment from "moment";
import {toast} from "react-toastify";

let signupCredentials = {
  company_name: '',
  username: '',
  firstname: '',
  surname: '',
  other_name: '',
  email: '',
  gender: 'MALE',
  dob: '',
  phone: '',
  nationality: '',
  secret_quest: '',
  secret_answer: '',
  address: '',
  city: '',
  state: '',
  lga: '',
  occupation: '',
  nin: '',
  password: '',
  passwordAgain: '',
}

class Register extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      isPasswordOk: false,
      ...signupCredentials
    }
    this.handleChange = this.handleChange.bind(this);
  };

  cssBorder = (id,val) => {
    return document.getElementById(id).style.border = val;
  }

  booleanElement = (id) => {
    return !!document.getElementById(id).value;
  }

  valueElement = (id) => {
    return document.getElementById(id).value;
  }

  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value,
    });
    this.cssBorder([e.target.id],'');
    console.log(e.target.value);
  }

  handleRegistration = async () => {
    let countEmpty = [];
    regArrValidateID.map((item) => {
      if (!this.booleanElement(item)) {
        this.cssBorder(item, '1px solid red');
        countEmpty.push(item);
      }
    });
    const {
      company_name,username,firstname,surname,other_name,email,phone,gender,
      dob,nationality,address,city,state,lga,occupation,nin,password,isPasswordOk
    } = this.state;

    if (countEmpty.length) {
      toast('Fill out all required fields!')
      return;
    }
    else if (['undefined', 'invalid'].includes(EmailValidator(email))) {
      await Toaster('warning','','Enter a valid email!');
      return;
    }
    else if (!isPasswordOk) {
      await Toaster('warning','','Invalid password!');
      return;
    }
    let data = JSON.stringify({
      company_name,username,firstname,surname,other_name,email,phone,gender,dob,nationality,address,city,state,lga,occupation,nin,password
    });
    try {
      this.setState({isLoading: true});
      await service.RegisterUser(data).then(async (res) => {
        this.setState({isLoading: false});
        if (res.code === '000') {
          await new Swal({
            icon: 'success',
            title: 'Registration Completed!',
            html: res.message,
            timer: 5000
          });
          await this.setState(signupCredentials);
        } else {
          await Toaster('warning', '', res.message);
        }
      })
    } catch (e) {
      console.log(e)
    }
  }

  viewPassword = (id) => {
    let x = document.getElementById(id);
    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }
  }

  render() {
    const { isLoading, passwordAgain,company_name,username,firstname,surname,other_name,email,phone,gender,dob,nationality,address,city,state,lga,occupation,nin,password} = this.state;
    return (
      <div>
        <div className="pageTitle">
          <div className="container">
            <div className="row">
              <div className="col-md-6 col-sm-6">
                <h1 className="page-heading">Register</h1>
              </div>
              <div className="col-md-6 col-sm-6">
                <div className="breadCrumb">
                  <Link to="/Home">Home</Link> / <a>Sign up</a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={'container'}>
          <div className={''}>
            <br />
            <br />

            <div className={'rptForm-section'}>
              <div className={'rptForm-header'}>
                <div className={'col-md-12 col-xs-12'}>
                  <h5 className={'section-header'}>
                    REGISTER AS A PORTAL USER
                  </h5>
                  <p className={'center text-danger'}>All fields with (*) are required.</p>
                  <hr />
                </div>
              </div>
              <div className={'row rptForm-body'}>
                <div className={'col-md-12'}>
                  <div className={'input-group'}>
                    <input type={'text'} id={'company_name'} value={company_name} className={'regInput'} onChange={this.handleChange} required={true} />
                    <label className={'placeholder'}>Organization / Individual *</label>
                  </div>
                </div>
                <div className={'col-md-6 col-xs-12'}>
                  <div>
                    <div>
                      <div className={'input-group'}>
                        <input type={'text'} id={'username'} value={username} className={'regInput'} onChange={this.handleChange} required={true} />
                        <label className={'placeholder'}>Organization / Individual username *</label>
                      </div>
                      <div className={'input-group'}>
                        <input type={'text'} id={'firstname'} value={firstname} className={'regInput'} onChange={this.handleChange} required={true} />
                        <label className={'placeholder'}>First name *</label>
                      </div>
                      <div className={'input-group'}>
                        <input type={'text'} id={'surname'} value={surname} className={'regInput'} onChange={this.handleChange} required={true} />
                        <label className={'placeholder'}>Surname *</label>
                      </div>
                      <div className={'input-group'}>
                        <input type={'text'} id={'other_name'} value={other_name} className={'regInput'} onChange={this.handleChange} required={true} />
                        <label className={'placeholder'}>Other name</label>
                      </div>
                      <div className={'input-group'}>
                        <input type={'text'} id={'email'} value={email} className={'regInput'} onChange={this.handleChange} required={true} />
                        <label className={'placeholder'}>Email address *</label>
                      </div>
                      <div style={{width: '70%'}}>
                        <PhoneInput
                          id={'phone'}
                          value={phone}
                          defaultCountry="NG"
                          placeholder="Phone # *"
                          onChange={(no) => {
                            this.setState({phone: no})
                            this.cssBorder('phone','');
                            console.log(no)
                          }}
                        />
                        <br />
                      </div>
                      <div>
                        <input style={radioCSS} checked={gender === 'MALE'} onClick={(gen)=>this.setState({gender: 'MALE'})} type="radio" name="gender" />
                        <label>Male</label> &nbsp;&nbsp;&nbsp;&nbsp;
                        <input style={radioCSS} checked={gender === 'FEMALE'} onClick={(gen)=>this.setState({gender: 'FEMALE'})} type="radio" name="gender" />
                        <label>Female</label><br/>
                      </div>
                      <br />
                      <div className={'input-group'}>
                        <p>Date of birth</p>
                        <DatePicker
                          id={'dob'}
                          classname={'regInput'}
                          selected={dob}
                          onChange={(dt) => {
                            this.setState({dob: dt})
                            this.cssBorder('report14','1px solid #ccc')
                          }}
                          dateFormat={'dd/MM/yyyy'}
                          showMonthDropdown
                          showYearDropdown
                          scrollableYearDropdown
                          maxDate={moment().subtract(18, 'years')._d}
                        />
                      </div>
                      <br /><br />
                      <div className={'input-group'}>
                        <input type={'text'} id={'nationality'} value={nationality} className={'regInput'} onChange={this.handleChange} required={true} />
                        <label className={'placeholder'}>Nationality *</label>
                      </div>
                      <div className={'input-group'}>
                        <input type={'text'} id={'address'} value={address} className={'regInput'} onChange={this.handleChange} required={true} />
                        <label className={'placeholder'}>House address</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={'col-md-6 col-xs-12'}>
                  <div>
                    <div >
                      <div className={'input-group'}>
                        <input type={'text'} id={'city'} value={city} className={'regInput'} onChange={this.handleChange} required={true} />
                        <label className={'placeholder'}>City</label>
                      </div>
                      <div className={'input-group'}>
                        <input type={'text'} id={'state'} value={state} className={'regInput'} onChange={this.handleChange} required={true} />
                        <label className={'placeholder'}>State</label>
                      </div>
                      <div className={'input-group'}>
                        <input type={'text'} id={'lga'} value={lga} className={'regInput'} onChange={this.handleChange} required={true} />
                        <label className={'placeholder'}>Local Government Area</label>
                      </div>
                      <div className={'input-group'}>
                        <input type={'text'} id={'occupation'} value={occupation} className={'regInput'} onChange={this.handleChange} required={true} />
                        <label className={'placeholder'}>Occupation</label>
                      </div>
                      <div className={'input-group'}>
                        <input type={'text'} id={'nin'} value={nin} className={'regInput'} onChange={this.handleChange} required={true} />
                        <label className={'placeholder'}>National Identification Number (NIN)</label>
                      </div>

                      <div className={'row'}>
                        <div className={'col-md-8 col-xs-12'}>
                          <div className={'col-md-6 col-xs-12 input-group'}>
                            <input type={'password'} value={password} className={'regInput'} id={'password'} required={true} onChange={this.handleChange} />
                            <label className={'placeholder'}>Create password *</label>
                            <i className={'fa fa-eye'} onClick={()=>this.viewPassword('password')}></i>
                          </div>
                          <div className={'col-md-6 col-xs-12 input-group'}>
                            <input type={'password'} value={passwordAgain} className={'regInput'} id={'passwordAgain'} required={true} onChange={this.handleChange} />
                            <label className={'placeholder'}>Re-type password *</label>
                            <i className={'fa fa-eye'} onClick={()=>this.viewPassword('passwordAgain')}></i>
                          </div>
                        </div>
                      </div>
                      <div className={'col-md-12 col-xs-12'}>
                        <PasswordChecklist
                          // rules={["minLength","match","specialChar"]}
                          rules={["minLength","specialChar","number","capital","match"]}
                          minLength={5}
                          value={password}
                          valueAgain={passwordAgain}
                          onChange={(isValid) => {this.setState({isPasswordOk: isValid})}}
                        />
                      </div>
                    </div>
                  </div>
                </div>

              </div>
              <hr />
              <div className={'center'}>
                <button
                  className={'btn btn-primary regSubmit'}
                  onClick={this.handleRegistration}
                  style={{marginBottom: 20}}>
                  Sign up
                </button>
              </div>
              <br />
              <br />
            </div>

            <br />
            <br />
            <br />

          </div>
        </div>

        <OverlayLoader
          loadingText={'Sending Feedback - please wait..!'}
          active={isLoading}
          variant={'ripple'}
        />

      </div>
    );
  }
}

export default Register;
