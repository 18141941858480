import React, {Component} from 'react';
import {Link} from "react-router-dom";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import OverlayLoader from "overlay-loading-react";
// import Faq from "../body/Faq";
import CustPagination from "../../component/molecules/CustPagination";
import WordLimit from 'react-word-limit';
import '../../assets/css/search.css';
import '../../assets/css/homeStyle.css';
import {Dropdown} from "../../component/atoms/Dropdown";
// import hammer from '../assets/img/remove.bg.png';
import hammer from '../../assets/img/Test.png';
import {service} from "../../api/service";
import {Toaster} from "../../component/molecules/Toaster";
import {dateConv} from "../../api/dateConv";
import {dateMonYear} from "../../api/dateMonYear";
import {alertMSG} from "../../component/atoms/alertMSG";
import {Spinner} from "react-activity";
import {toast} from "react-toastify";

class Search extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      searchControl: false, // tells if search has been perfumed.
      searchedItemForDisplay: null,
      searchItem: this.props.item,
      searchType: this.props.type,
      searchSwitch: true,
      searchReturns: [], //data after fetch occurs.
      pscReturns: [], //psc data fetch.
      numberOfRows: null,
      isOnline: false,
      companyIDSaveState: '',
    }
    this.handleChange = this.handleChange.bind(this);
    this.getPSCInformation = this.getPSCInformation.bind(this);
  }

  MySwal = withReactContent(Swal);
  handleChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
    })
  }
  handleSearch = async (type, item) => {
    if (item && type) {
      await service.Internet().then(async (web) => {
        if (type === 'ENTITY NAME' && item.length < 4) {
          await toast('Your search item should have more than 3 letter words!');
          return;
        }
        if (['PSC FULLNAME','ENTITY NAME'].includes(type) && /^\d+$/.test(item)) {
          toast('Enter a valid word to search!')
          return
        }
        if (['PSC FULLNAME','ENTITY NAME'].includes(type) && /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w\w+)+$/.test(item)) {
          toast('Email not allowed here!')
          return
        }
        if (['ENTITY NUMBER'].includes(type) && !/^\d+$/.test(item)) {
          toast('Please a valid Entity Number!')
          return
        }
        else if (web) {
          // type = item && !type ? 'ENTITY NAME' : type;
          this.setState({isLoading: true, searchControl: 1, searchedItemForDisplay: item});
          await service.GetSearched(item, type).then(async (res) => {
            if (!res.length) {
              new Swal({
                // title: 'Processing Error!',
                html: 'No data found!',
                icon: 'warning'
              });
              return false;
            } else {
              this.setState({
                searchReturns: res,
                numberOfRows: res.length,
                searchSwitch: true,
              });
            }
            this.setState({isLoading: false});
            // console.log(res);
          })
        } else {
          await Toaster(
              'warning',
              alertMSG.offlineTitle,
              alertMSG.offlineHTML
          );
        }
        this.setState({isLoading: false});
      });
    } else {
      toast('Please fill up required fields!')
    }
  }
  ver = async () => {
    let token = sessionStorage.getItem('token');
    return await service.getProfileData(token);
  }
  async componentDidMount() {
    if (this.props.type && this.props.item) {
      await this.handleSearch(this.props.type,this.props.item);
    }
    this.ver().then((r) => {
      if (r.flag) {
        this.setState({isOnline: true});
      }
    })
  }
  getPSCInformation = async (psc) => {
    if (!navigator.onLine) {
      await Toaster(
          'warning',
          alertMSG.offlineTitle,
          alertMSG.offlineHTML
      )
      return false;
    }
    this.setState({isLoading: true, searchControl: 2,companyIDSaveState: psc});
    await service.GetPSCDetails(psc).then(res => {
      console.log(res);
      this.setState({
        pscReturns: res,
        searchSwitch: res.length && false,
      })
      this.setState({isLoading: false});
    });
  }
  Post = (props) => {
    const { companyId, approvedName, rcNumber, status, numberOfPsc, registrationDate } = props.data;
    const { numberOfRows } = this.state;
    return (
        <li className={numberOfRows > 1 ? 'col-md-6 col-xs-12' : 'col-md-6 col-md-offset-3 col-xs-12'}>
          <div className="cacint">
            <div className="row">
              <div className={'col-md-12 col-xs-12'}>
                <div id={'card-name'} style={{flexDirection: 'row'}}>
                  <i style={{fontSize: 22,marginRight: 10}} className="fa fa-home"></i>
                  <WordLimit limit={40}>{approvedName}</WordLimit>
                </div>
                <hr style={{marginTop: 3,marginBottom: 10}} />
                <div className={'col-md-12 col-xs-12'}>
                  <div className="col-md-8 col-sm-8">
                    <div className="cacloc" style={{display: 'list-item',listStyleType: 'square',marginBottom: 5}}>
                      <text>Rc Number: </text>
                      <a href="javascript:void(0)"
                         onClick={()=>this.getPSCInformation(companyId)}
                         style={{fontWeight: 500}}>
                        {rcNumber}
                      </a>
                    </div>
                    <div className="cacloc" style={{display: 'list-item',listStyleType: 'square'}}>
                      <text>Date of Registration: </text>
                      <a href="javascript:void(0)"
                         style={{fontWeight: 500}}>
                        {registrationDate ? dateConv(registrationDate) : 'N/A'}
                      </a>
                    </div>
                    <br />
                    <div style={{display: 'list-item',listStyleType: 'square'}}>
                      <a href="javascript:void(0)"
                         className={'pscCard'}
                         onClick={()=>this.getPSCInformation(companyId)}>
                        {numberOfPsc} PSCs Available
                      </a>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-4">
                    <div className={'row'}>
                      <a style={{textAlign: 'center',width: '100%'}}
                         href="javascript:void(0)"
                         onClick={()=>this.getPSCInformation(companyId)}
                         className="applybtn">
                        View PSC
                      </a>
                    </div>
                    <div className={'row'} style={{paddingTop: 10}}>
                      <text>Status: </text>
                      <a href="javascript:void(0)"
                         style={{
                           color: status === 'ACTIVE'
                               ? 'green' : 'red',
                           fontSize: 13,
                           fontWeight: 500
                         }}> &nbsp;&nbsp;
                        {status}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </li>
    );
  }
  msgDisplay = () => {
    const {
      numberOfRows,
      searchControl,
      searchedItemForDisplay,
      searchReturns,
      isLoading
    } = this.state;

    let noOfPsc = 0;

    searchReturns.map((item) => {
      noOfPsc += item.numberOfPsc;
    })
    if (numberOfRows && searchControl && !isLoading) {
      return (
          <div className={'alert alert-warning'}>
            Found
            <span className="danger"> {noOfPsc} </span> PSCs,
            From <span className="danger"> {numberOfRows} </span> {numberOfRows > 1 ? 'companies' : 'company'}
            <span className="text-success" style={{fontFamily: 'monospace'}}> associated with ({searchedItemForDisplay})
                    </span>
          </div>
      )
    } else if (!numberOfRows && searchControl && !isLoading) {
      return <p style={{color: 'red',fontSize: 25}}>No match found!</p>;
    }
  }
  nullHandler = (value) => {
    return !value ? '' : value;
  }
  isNigerian = (nationality) => {
    if (!nationality) {
      return 'N/A';
    } else if (nationality.toUpperCase() === 'NIGERIA') {
      return 'NIGERIAN';
    } else {
      return nationality;
    }
  }
  downloadFormat = async (format) => {
    await service.TriggerDownload(this.state.pscReturns, format);
  }
  PrintElem = () => {
    var divContents = document.getElementById("GFG").innerHTML;
    var a = window.open('', '', 'height=500, width=500');
    let curr_date = new Date();
    a.document.write('<html lang="en">');
    a.document.write(`<body> Time: ${curr_date} <br>`);
    a.document.write(divContents);
    a.document.write('</body></html>');
    a.document.close();
    a.print();
  }
  render() {
    const {isLoading,searchReturns,numberOfRows,pscReturns,searchSwitch,searchType,searchItem,isOnline} = this.state;
    return (
        <div>
          <div className="pageTitle">
            <div className="container">
              <div className="row">
                <div className="col-md-6 col-sm-6">
                  <h1 className="page-heading">Search</h1>
                </div>
                <div className="col-md-6 col-sm-6">
                  <div className="breadCrumb"><Link to="/Home">Home</Link> / <a>Search</a></div>
                </div>
              </div>
            </div>
          </div>
          <div className={'shadow'}>
            <div id={'search-holder'}>
              <input
                  id={'searchItem'}
                  type={'text'}
                  placeholder={'Quick search..!'}
                  // value={searchItem}
                  onChange={this.handleChange}
              />
              <select id={'searchType'} onChange={this.handleChange}>
                {Dropdown()}
              </select>
              <button id={'searchSubmit'} onClick={()=> this.handleSearch(searchType, searchItem)}>
                Search
              </button>
            </div>
            <div className={searchType === 'PSC FULLNAME' ? '' : 'hide'} style={{textAlign: 'center',marginTop: 10,fontWeight: 500,color: '#fa8d5e'}}>
              Please enter full name in the format: (1. sur-name * / 2. first name * / 3. other name)
            </div>
          </div>
          <div className={searchSwitch && 'listpgWraper'}>
            <div className="container">
              <div className="row">
                <div className="col-md-12 ">
                  <div className="">
                    <div className="titleTop">
                      {searchSwitch && this.msgDisplay()}
                    </div>
                    {
                      searchSwitch ?  (
                          <ul className="cacslist row">
                            {numberOfRows > 0 ? (
                                <CustPagination
                                    data={searchReturns}
                                    RenderComponent={this.Post}
                                    title="Result(s)"
                                    dataLimit={10}
                                />

                            ) : (
                                <div style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                }}>
                                  <img src={hammer} alt={'BG'} className={'centerCAC'} />
                                </div>
                            )}
                          </ul>
                      ) : (
                          <div className="row" style={{marginBottom: 50}}>
                            <div className="col-md-12 ">
                              <div style={{marginBottom: 20}}>
                                <button className={'backButton shadow'} onClick={()=>this.setState({searchSwitch: true})}>
                                  <i className={'glyphicon glyphicon-circle-arrow-left'}>&nbsp;</i>
                                  Go Back
                                </button>
                                {
                                    isOnline && (
                                        <>
                                          <button className={'backButton shadow ml-10'} onClick={()=>this.downloadFormat('json')}>
                                            <i className={'glyphicon glyphicon-download'}></i>{' '}
                                            Download JSON
                                          </button>
                                          <button className={'backButton shadow ml-10'} onClick={this.PrintElem}>
                                            <i className={'glyphicon glyphicon-print'}></i>{' '}
                                            Print
                                          </button>
                                        </>
                                    )
                                }
                              </div>
                              <div className="container">
                                <div className="col-md-12 cac-header" id={'GFG'}>
                                  <br />
                                  <br />
                                  <h4
                                      className={'center text-success'}
                                      style={{
                                        fontWeight: 900,
                                        textDecoration: 'underline'
                                      }}>
                                    PERSONS WITH SIGNIFICANT CONTROL (PSC) {pscReturns.length} Found
                                  </h4>
                                  {
                                    pscReturns.map((item,index) => {
                                      return (
                                          <div key={index}>
                                            <div className="col-md-12 center" style={{marginTop: 10}}>
                                              <h3 className="pd-2 text-center text-success" id="company-name-1">
                                                {item.approvedName}
                                              </h3>
                                              <h6 style={{color: '#fa5e5e'}}>
                                                ( RC {item.rcNumber} )
                                              </h6>
                                              <div className="cac-header shadow" style={{background: '#fbfdf9'}}>
                                                <div className="">
                                                  <div className="row text-center">

                                                    <div className="col-md-12">
                                                      <div><i className="glyphicon glyphicon-user" style={{fontSize: 50}}></i></div>
                                                      <br />
                                                      <h4 className="text-success" style={{textTransform: 'uppercase'}}>
                                                        {this.nullHandler(item.affiliatesSurname) + ' ' + this.nullHandler(item.affiliatesFirstname) + ' ' + this.nullHandler(item.otherName)}
                                                      </h4>

                                                      <div className="salary">
                                                        <p>Date of Notification:</p>
                                                        <strong className="text-primary" style={{fontSize: 19}}>
                                                          {item.dateOfAppointment ? dateConv(item.dateOfAppointment) : 'N/A'}
                                                        </strong>
                                                      </div>
                                                      <br />
                                                      <div className="salary">
                                                        <p>Occupation:</p>
                                                        <strong className="text-primary" style={{fontSize: 19}}>
                                                          {item.occupation ? item.occupation : 'N/A'}
                                                        </strong>
                                                      </div>
                                                      <br />
                                                      <div
                                                          className="salary">
                                                        <p>Service Address:</p>
                                                        <strong className="text-success" style={{textTransform: 'uppercase'}}>
                                                          {
                                                              this.nullHandler(item.affiliatesStreetNumber) + ' ' +
                                                              this.nullHandler(item.affiliatesAddress) + ' ' +
                                                              this.nullHandler(item.affiliatesCity) + ' ' +
                                                              this.nullHandler(item.affiliatesCity) + ' ' +
                                                              this.nullHandler(item.affiliatesState)
                                                          }
                                                        </strong>
                                                      </div>
                                                      <br />
                                                      <div className="cacloc">
                                                        <span><b>Status</b></span> - <label
                                                          className="fulltime">
                                                        {item.status} </label>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                                <br />
                                                <br />
                                              </div>
                                            </div>
                                            <div key={index}>
                                              <div className="row" style={{marginLeft: 50, marginRight: 50}}>
                                                <div className="col-md-12">

                                                  <div className={'row'}>

                                                    <ul className="col-md-6 bulletins" style={{listStyleType: 'square'}}>
                                                      <li>
                                                        <p>Governing Law</p>
                                                        {item.governingLaw ? item.governingLaw : 'N/A'}
                                                      </li>
                                                      <hr />
                                                      <li>
                                                        <p>Country of Registration</p>
                                                        {/*{item.taxResidencyOrJurisdiction ? item.taxResidencyOrJurisdiction : 'N/A'}*/}
                                                        NIGERIA
                                                      </li>
                                                      <hr />
                                                      <li>
                                                        <p>Date Of Birth</p>
                                                        {item.dateOfBirth ? dateMonYear(item.dateOfBirth) : 'N/A'}
                                                      </li>
                                                    </ul>

                                                    <ul className="col-md-6 bulletins" style={{listStyleType: 'square'}}>
                                                      <li>
                                                        <p>Register</p>
                                                        {item.register ? item.register : 'N/A'}
                                                      </li>
                                                      <hr />
                                                      <li>
                                                        <p>Nationality</p>
                                                        {item.nationality ? this.isNigerian(item.nationality) : 'N/A'}
                                                      </li>
                                                      <hr />
                                                      {/*<li>
                                                  <p>Email</p>
                                                  {item.affiliatesEmail ? item.affiliatesEmail : 'N/A'}
                                                </li>*/}
                                                    </ul>

                                                  </div>

                                                  <hr />

                                                  <ul className="bulletins" style={{listStyleType: 'square'}}>
                                                    <li>
                                                      <p> Does the PSC directly or indirectly hold at
                                                        least [5%] of the shares or interest in a company
                                                        or
                                                        limited liability partnership?
                                                      </p>
                                                      {
                                                        item.pscHoldsSharesOrInterest ? (
                                                            'YES Directly: [ ' +
                                                            item.pscHoldsSharesOrInterestPercentageHeldDirectly +
                                                            '% ] and Indirectly: [ ' +
                                                            item.pscHoldsSharesOrInterestPercentageHeldIndirectly + '% ]'
                                                        ) : 'No'
                                                      }
                                                    </li>
                                                    <hr />
                                                    <li>
                                                      <p> Does the PSC directly or indirectly hold at
                                                        least [5%] of the voting rights in a company or
                                                        limited liability partnership? </p>
                                                      {
                                                        item.pscVotingRights ? (
                                                            'YES Directly: [ ' +
                                                            item.pscVotingRightsPercentageHeldDirectly +
                                                            '% ] and Indirectly: [ ' +
                                                            item.pscVotingRightsPercentageHeldIndirectly + '% ]'
                                                        ) : 'No'
                                                      }
                                                    </li>
                                                    <hr />
                                                    <li>
                                                      <p> Does the PSC directly or indirectly hold the
                                                        right to appoint or remove a majority of the
                                                        directors or partners in a company or limited
                                                        liability partnership? </p>
                                                      {item.pscRightToAppoints ? 'TRUE' : 'FALSE'}
                                                    </li>
                                                    <hr />
                                                    <li>
                                                      <p> Does the PSC otherwise have the right to
                                                        exercise or is actually exercising significant
                                                        influence or control over a company or limited
                                                        liability partnership?
                                                      </p>
                                                      {item.pscExeriseSignificantInfluence ? 'TRUE' : 'FALSE'}
                                                    </li>
                                                    <hr />
                                                    <li>
                                                      <p>
                                                        Does the PSC have the right to exercise, or
                                                        actually exercise significant influence or
                                                        control
                                                        over the activities of a trust or firm, whether
                                                        or not it is a legal entity, but would itself
                                                        satisfy any of the first four conditions if it
                                                        were an individual?
                                                      </p>
                                                      {item.pscExeriseSignificantInfluence ? 'TRUE' : 'FALSE'}
                                                    </li>
                                                  </ul>

                                                </div>

                                              </div>
                                              <hr
                                                  style={{
                                                    borderWidth: 5,
                                                    borderColor: 'darkcyan',
                                                    marginBottom: 1,
                                                    marginTop: 50
                                                  }}
                                              />
                                              <hr
                                                  style={{
                                                    borderWidth: 2,
                                                    borderColor: 'darkcyan',
                                                    marginBottom: 50,
                                                    marginTop: 2
                                                  }}
                                              />
                                            </div>
                                          </div>
                                      )
                                    })
                                  }

                                </div>
                                <div style={{marginBottom: 10}}>
                                  <button className={'backButton shadow'} onClick={()=>this.setState({searchSwitch: true})}>
                                    <i className={'glyphicon glyphicon-circle-arrow-left'}>&nbsp;</i>
                                    Go Back
                                  </button>
                                  {
                                      isOnline && (
                                          <>
                                            <button className={'backButton shadow ml-10'} onClick={()=>this.downloadFormat('json')}>
                                              <i className={'glyphicon glyphicon-download'}></i>{' '}
                                              Download JSON
                                            </button>
                                            <button className={'backButton shadow ml-10'} onClick={this.PrintElem}>
                                              <i className={'glyphicon glyphicon-print'}></i>{' '}
                                              Print
                                            </button>
                                          </>
                                      )
                                  }
                                </div>
                              </div>
                            </div>
                          </div>
                      )
                    }

                  </div>
                </div>
              </div>
            </div>
          </div>
          <OverlayLoader
              loadingText={'Searching - please wait..!'}
              active={isLoading}
              variant={'ripple'}
          />
        </div>
    );
  }
}

export default Search;
