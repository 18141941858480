import React from "react";
import {titleCase} from "./titleCase";

export const Dropdown = () => {

    /*let options = [
        {value: 'SearchType', label: 'Search Type'},
        {value: 'EntityName', label: 'ENTITY NAME'},
        {value: 'EntityNumber', label: 'ENTITY NUMBER'},
        {value: 'PscFirstName', label: 'PSC FIRST NAME'},
        {value: 'PscSurName', label: 'PSC SURNAME'},
        {value: 'PscFullName', label: 'PSC FULL NAME'}
    ];  */

    let options = [
        {value: 'SearchType', label: 'Search Type'},
        {value: 'ENTITY NAME', label: 'ENTITY NAME'},
        {value: 'ENTITY NUMBER', label: 'ENTITY NUMBER'},
        // {value: 'PSC FIRST NAME', label: 'PSC FIRST NAME'},
        // {value: 'PSC SURNAME', label: 'PSC SURNAME'},
        {value: 'PSC FULLNAME', label: 'PSC FULL NAME'}
    ];

    return options.map((item,index) => {
        return <option key={index} selected={index === 0} disabled={index === 0} value={item.value}>{item.label}</option>
    })
}
