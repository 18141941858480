import React, {Component} from 'react';
import logo from '../../assets/img/logo.png'
import {Link} from "react-router-dom";
import '../../assets/css/header.css';
import '../../assets/css/modalStyle.css';
import '../../assets/css/cssstyle.css';
import '../../assets/css/swalStyle.css';
import Modal from "react-modal";
import Swal from "sweetalert2";
import {service} from "../../api/service";
import OverlayLoader from "overlay-loading-react";
import {Spinner} from "react-activity";
import {toast} from "react-toastify";
import {env} from "../../component/atoms/env";

class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      signinLoading: false,
      subtitle: null,
      modalIsOpen: false,
      username: '',
      password: '',
      isLoggedIn: !!sessionStorage.getItem('token'),
      mobShow: false,
    }
    this.handleLoginInputs = this.handleLoginInputs.bind(this);
  }

  openModal = () => {
    this.setState({modalIsOpen: true, mobShow: false});
  }

  closeModal = () => {
    this.setState({modalIsOpen: false});
  }

  handleLoginInputs = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
    })
  }

  handleLogin = async () => {
    const { username, password } = this.state;
    if (!username || !password) {
      toast(env.message.required_fields)
      return;
    }
    if (await service.Internet()) {
      try {
        this.setState({signinLoading: true});
        await service.Portal_Login(username,password).then(async (res) => {
          toast(res.message)
          if (res.flag) {
            this.setState({
              isLoggedIn: true,
              modalIsOpen: false,
              username: '',
              password: ''
            });
          }
          this.setState({signinLoading: false});
        });
      } catch (e) {
        console.log(e)
      }
    } else {
      toast(env.message.no_internet)
    }
  }

  gotoSignUp = () => {
    this.closeModal();
    setTimeout(() => {
      window.location.replace('#/Register');
    }, 500)
  }

  confirmLogout = () => {
    new Swal({
      title: '<strong>CONFIRM <u>LOGOUT</u></strong>',
      icon: 'question',
      html: 'Are you sure?',
      showCancelButton: true,
      focusConfirm: true,
      confirmButtonText: 'Logout',
      cancelButtonText: 'Cancel',
    }).then(async (result) => {
      if (result.isConfirmed) {
        await service.Logout();
        await this.setState({isLoggedIn: false, mobShow: false});
        await window.location.replace('#/Home');
        toast('Logout completed!')
      }
    })
  }

  homeButton = () => {
    let uri = window.location.hash;
    let url = uri.split('/')[1];

    if (url === 'Home') {
      window.location.reload()
    } else {
      window.location.replace('#/Home')
    }
    this.setState({mobShow: false})
  }
  routerAgent = (r) => {
    this.setState({mobShow: false})
  }
  render() {
    const {modalIsOpen,signinLoading,isLoggedIn,mobShow} = this.state;
    return (
        <div className="">
          <div className="container">
            <div className="row">
              <div className="col-md-2 col-sm-3 col-xs-12">
                <div style={{display: 'flex',justifyContent: 'space-between'}}>
                  <Link to="/Home" className="logo">
                    <img src={logo} className={'header-logo'} alt="Logo"/>
                  </Link>
                  {
                    isLoggedIn ? (<button className={'mobile-menu-button mobile-show'} onClick={this.confirmLogout}>
                          <i className={'fa fa-lock text-danger'}></i>
                        </button>)
                        : (<button className={'mobile-menu-button mobile-show'} onClick={this.openModal}>
                          <i className={'fa fa-lock text-secondary'}></i>
                        </button>)
                  }

                  <button
                      className={'mobile-menu-button mobile-show'}
                      onClick={()=>this.setState({mobShow: !mobShow})}
                  >
                    <i className={'fa fa-folder'}></i>
                  </button>
                </div>
                <div className="clearfix"></div>
              </div>

              <div className="col-md-10 col-sm-12 col-xs-12">

                <div style={{display: mobShow ? 'flex' : 'none',flexDirection:'column'}}>
                  <hr style={{marginTop:10,marginBottom:10,width:150}} />
                  <Link to={'/Home'} onClick={this.homeButton} style={{margin: 5}}>Home</Link>
                  <hr style={{marginTop:10,marginBottom:10,width:150}} />
                  <Link to={'/Search'} onClick={this.routerAgent} style={{margin: 5}}>Search</Link>
                  <hr style={{marginTop:10,marginBottom:10,width:150}} />
                  <Link to={'/Register'} onClick={this.routerAgent} style={{margin: 5}}>Register</Link>
                  <hr style={{marginTop:10,marginBottom:10,width:150}} />
                  <Link to={'/Report'} onClick={this.routerAgent} style={{margin: 5}}>Report</Link>
                  <hr style={{marginTop:10,marginBottom:10,width:150}} />
                  <Link to={'/Faq'} onClick={this.routerAgent} style={{margin: 5}}>Faq</Link>
                  <hr style={{marginTop:10,marginBottom:10,width:150}} />
                  {
                    isLoggedIn ? (<Link to={'#'} onClick={this.confirmLogout} style={{margin: 5, color: '#ab0707'}}>
                          Logout &nbsp;<i className={'fa fa-lock'}></i>
                        </Link>)
                        : (<Link to={'#'} onClick={this.openModal} style={{margin: 5}}>
                          Login &nbsp;<i className={'fa fa-lock'}></i>
                        </Link>)
                  }
                  <hr style={{marginTop:20,marginBottom:20,width:150}} />
                </div>

                <div className="navbar navbar-default" role="navigation">

                  <div className="navbar-collapse collapse" id="nav-main">

                    <ul className="nav navbar-nav" style={{flexDirection: 'row'}}>
                      {
                        isLoggedIn ?
                            <>
                              <li><Link to={'/Dashboard'}>Dashboard</Link></li>
                              <li><Link to={'/MyProfile'}>My Profile</Link></li>
                              <li><Link to={'/Download'}>Download File</Link></li>
                            </>
                            :
                            <>
                              <li><a href="javascript:void(0)" onClick={this.homeButton}>Home</a></li>
                              <li><Link to="/Standards">Standards</Link></li>
                              <li><Link to="/AboutBOR">About CAC-BOR</Link></li>
                              <li><Link to="/Faq">Faq</Link></li>
                            </>
                      }
                      <li><Link to="/Search">Search</Link></li>
                      <li><Link to="/Report">Report</Link></li>
                      {
                        isLoggedIn ?
                            <li><Link to={"#"} style={{color:'#c62828'}} onClick={this.confirmLogout}>Logout</Link></li> :
                            <>
                              <li><Link to="/Register">Register</Link></li>
                              <li>
                                <a className={'dropdown'}>
                                  <button className={'dropbtn'}>LOGIN</button>
                                  <div className={'dropdown-content'}>
                                    <Link to={"#"} onClick={this.openModal}>User Login</Link>
                                    <Link to={"https://boradmin.cac.gov.ng/"}>Admin Login</Link>
                                  </div>
                                </a>
                              </li>
                            </>
                      }
                    </ul>
                  </div>
                  <div className="clearfix"></div>
                </div>
              </div>
            </div>
          </div>

          <Modal
              isOpen={modalIsOpen}
              // onAfterOpen={this.afterOpenModal}
              onRequestClose={this.closeModal}
              shouldCloseOnOverlayClick={!signinLoading}
              style={{
                overlay: {
                  position: 'fixed',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  backgroundColor: 'transparent'
                },
              }}
              className='modal-content'
              // dialogClassName='modal-container'
              // keyboard
              // centered
              // show={show}
              // onHide={handleClose}
          >

            <div style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              // height: '100vh',
            }}>

              <div className="box">

                <div className={'form login_bg'} style={{pointerEvents: signinLoading && 'none'}}>

                  {/*<button id={'closeModal'} onClick={this.closeModal}>x</button>*/}
                  <h2>Portal User <br />Login</h2>

                  <div className={'inputBox'}>
                    <input type={'text'} required={true} id={'username'} onChange={this.handleLoginInputs} />
                    <span>Username</span>
                    <i></i>
                  </div>
                  <div className={'inputBox'}>
                    <input type={'password'} required={true} id={'password'} onChange={this.handleLoginInputs} />
                    <span>Password</span>
                    <i></i>
                  </div>
                  <br />
                  <div className={'links'}>
                    <a href="#/Recover" onClick={this.closeModal}>Forgot Password</a>
                    <a href="#/Register" onClick={this.closeModal}>Signup</a>
                  </div>

                  <br />
                  <br />
                  {signinLoading ?
                      <Spinner /> : (
                          <button className={'submit'} disabled={signinLoading} onClick={this.handleLogin}>
                            Sign-in
                          </button>)}
                </div>

                <OverlayLoader
                    loadingText={'Sending Feedback - please wait..!'}
                    active={signinLoading}
                    variant={'ripple'}
                />

              </div>

            </div>
          </Modal>
        </div>
    );
  }
}

export default Header;
