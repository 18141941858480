import {urls} from "./urls";
import {dateConv} from "./dateConv";

export const service = {
    DateRangeSearch: async (start, end) => {
        let token = sessionStorage.getItem('token')
        return await fetch(urls.endpoint + urls.dataDownload, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json; charset=UTF-8",
                "Accept": "application/json"
            },
            body: JSON.stringify({
                startTime: start,
                endTime: end
            }),
        }).then((res) => {
            return res.json();
        });
    },

    TriggerDownload: async (json_data, format) => {
        const fileName = "data." + format;
        const data = new Blob([JSON.stringify(json_data, null, 2)], { type: "text/" + format });
        const jsonURL = window.URL.createObjectURL(data);
        const link = document.createElement("a");
        document.body.appendChild(link);
        link.href = jsonURL;
        link.setAttribute("download", fileName);
        link.click();
        document.body.removeChild(link);
    },

    Internet: async () => {
        return navigator.onLine;
    },

    GetSearched: async (searchItem,searchType) => {
        return await fetch(urls.endpoint + urls.searchDetails, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json; charset=UTF-8",
                "Accept": "application/json"
            },
            body: JSON.stringify({
                searchItem, searchType
            }),
        }).then(res => {
            return res.json().then((r) => {
                return r;
            })
        });
    },

    GetPSCDetails: async (id) => {
        return await fetch(urls.endpoint + urls.PSCDetails, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json; charset=UTF-8",
                "Accept": "application/json"
            },
            body: JSON.stringify({
                id
            }),
        }).then(res => {
            return res.json().then((r) => {
                return r;
            })
        });
    },

    LoginAbort: async () => {
        await sessionStorage.removeItem("uid");
        await sessionStorage.removeItem("token");
        await sessionStorage.removeItem("type");
        // await window.location.reload();
    },

    Logout: async () => {
        let type = sessionStorage.getItem('type');
        let token = sessionStorage.getItem('token');

        return await fetch(urls.endpoint + urls.logout, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json; charset=UTF-8",
                "Accept": "application/json"
            },
            body: JSON.stringify({token,type}),
        }).then(async res => {
            await service.LoginAbort();
            return res.json().then((r) => {
                return r;
            })
        });
    },

    RegisterUser: async (data) => {
        return await fetch(urls.endpoint + urls.signup, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json; charset=UTF-8",
                "Accept": "application/json"
            },
            body: data,
        }).then(res => {
            return res.json().then((r) => {
                return r;
            })
        });
    },

    getProfileData: async (token) => {
        return await fetch(urls.endpoint + urls.profile_user_data, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json; charset=UTF-8",
                "Accept": "application/json"
            },
            body: JSON.stringify({token}),
        }).then(res => {
            return res.json().then(async (r) => {
                return {
                    flag: r.flag,
                    firstname: r.data.firstName,
                    surname: r.data.surname,
                    other_name: r.data.otherName,
                    last_logon: r.data.last_logon,
                    company_name: r.data.companyName,
                    username: r.data.username,
                    email: r.data.email,
                    gender: r.data.gender,
                    occupation: r.data.occupation,
                    state: r.data.state,
                    city: r.data.city,
                    lga: r.data.lga,
                    dob: dateConv(r.data.dateOfBirth),
                    phone: r.data.phone,
                    address: r.data.address,
                    nin: r.data.nin
                }
            })
        });
    },

    portal_user_update: async (data) => {
        return await fetch(urls.endpoint + urls.update_portal_user, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json; charset=UTF-8",
                "Accept": "application/json"
            },
            body: data,
        }).then(res => {
            return res.json().then((r) => {
                return r;
            })
        });
    },

    post_contact_us: async (data) => {
        return await fetch(urls.endpoint + urls.contact_us, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json; charset=UTF-8",
                "Accept": "application/json"
            },
            body: data,
        }).then(res => {
            return res.json().then((r) => {
                return r;
            })
        });
    },

    Portal_Login: async (username,password) => {
        return await fetch(urls.endpoint + urls.login, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json; charset=UTF-8",
                "Accept": "application/json"
            },
            body: JSON.stringify({
                username, password
            }),
        }).then(async res => {
            return res.json().then(async (r) => {
                if (!!r.flag) {
                    await sessionStorage.setItem("type", r.type);
                    await sessionStorage.setItem("token", r.tempToken);
                    await sessionStorage.setItem("uid", r.username);
                    // await window.location.replace(`#/MyProfile/${r.data.uid}/${r.data.token}/${r.data.type}`);
                    await window.location.replace(`#/Dashboard`);
                    await window.location.reload();
                }
                return r;
            })
        });
    },

    Recover_Password: async (username,password,code,isOTP) => {
        return await fetch(urls.endpoint + urls.recover_password, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json; charset=UTF-8",
                "Accept": "application/json"
            },
            body: JSON.stringify({
                username,password,code,isOTP
            }),
        }).then(res => {
            return res.json().then((r) => {
                return r;
            })
        });
    },

}
